import React, { useContext } from 'react'
import TextField from './TextField'
import SearchIcon from '@mui/icons-material/Search'
import color from '../styles/color'
import { ThemeContext } from './ThemeContext'
const SearchField = (props) => {
  const { theme } = useContext(ThemeContext)
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <TextField
        {...props}
        style={theme.searchBox}
        icon={<SearchIcon style={{ color: color.primary }} />}
        iconPosition={'start'}
        placeholder="Search"
      />
    </div>
  )
}

export default SearchField
