import MUILoadingButton from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'
import Typography from './Typography'
import { ThemeContext } from './ThemeContext'
import React, { useContext } from 'react'

const CssLoadingButton = styled(MUILoadingButton)(
  ({ style, theme, source }) => {
    return source
      ? { ...theme?.loadingButton, ...theme[source], ...style }
      : { ...theme?.loadingButton, ...style }
  }
)

const LoadingButton = (props) => {
  const { children } = props
  const { theme } = useContext(ThemeContext)
  return (
    <CssLoadingButton {...props} theme={theme}>
      <Typography>{children}</Typography>
    </CssLoadingButton>
  )
}

MUILoadingButton.defaultProps = {
  variant: 'contained',
  loadingPosition: 'end',
}
export default LoadingButton
