import React from 'react'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '../../ui-library/components/Typography'
import { formData } from '../../resources/formData'
import SearchField from '../../ui-library/components/SearchField'

const TabHeader = (props) => {
  const {
    tabTitle,
    userName,
    isEdit,
    children,
    showSearch,
    onChangeSearch,
    searchValue,
  } = props
  return (
    <>
      <Paper
        style={{
          backgroundColor: 'black',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          height: '85px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 0 0 15px',
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography source="minicrumb">
              {formData.shared.path} {tabTitle}
              {isEdit ? '' : userName ? ` > ${userName}` : ''}
            </Typography>
            <Typography source="breadcrumb">
              {tabTitle}
              {isEdit ? '' : userName ? `: ${userName}` : ''}
            </Typography>
          </Stack>
          {showSearch ? (
            <SearchField onChange={onChangeSearch} value={searchValue} />
          ) : (
            <></>
          )}
        </div>
      </Paper>
      {children}
    </>
  )
}

export default TabHeader
