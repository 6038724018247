import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import { AccordionDetails, AccordionSummary } from '@mui/material'

const CssAccordion = styled(MuiAccordion)((props) => {
  const { theme, source, style } = props
  return {
    ...theme?.accordion,
    ...theme?.accordion?.[source],
    ...style,
  }
})

const Accordion = ({ title, children, Icon }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <CssAccordion
      elevation={0}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={
          <>{/* <BiChevronDown size={35} color={color.primary} /> */}</>
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {title}
        <div
          onClick={(event) => event.stopPropagation()}
          style={{ float: 'right', width: '40%', marginTop: -25 }}
        >
          {Icon}

          {/* {expanded ? 'Hide' : 'Show'} */}
        </div>
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </CssAccordion>
  )
}

export default Accordion
