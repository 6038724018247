import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

import style from '../../style/style'
import logo from '../../assets/light-logo.png'

import { loginSchema } from '../../config/validation'
import { loginHandler } from '../../handlers/requests/auth/loginHandler'
import { UserContext } from '../../context/UserContext'
import { AlertContext } from '../../context/AlertContext'
import { OrganisationContext } from '../../context/OrganisationContext'
import TextField from '../../ui-library/components/TextField'
import Logo from '../../ui-library/components/Logo'
import Link from '../../ui-library/components/Link'
import LoadingButton from '../../ui-library/components/LoadingButton'
import color from '../../ui-library/styles/color'
import PasswordField from '../../ui-library/components/PasswordField'
import uiText from '../../resources/uiText'
import Typography from '../../ui-library/components/Typography'
import { IoIosArrowDropright } from 'react-icons/io'
import LoadingAnim from '../../ui-library/components/loadingAnim/LoadingAnim'

export default function Login() {
  const navigate = useNavigate()
  const signupData = uiText.login
  const [loading, setLoading] = useState(false)
  const { setUser } = useContext(UserContext)
  const { setAlert } = useContext(AlertContext)
  const { setOrganisations } = useContext(OrganisationContext)

  return (
    <Grid item xs={12}>
      {loading ? (
        <div
          style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingAnim />
        </div>
      ) : (
        <>
          <Formik
            validationSchema={loginSchema}
            initialValues={{
              username: '',
              password: '',
            }}
            onSubmit={async (values) => {
              await loginHandler(
                values,
                navigate,
                setAlert,
                setLoading,
                setUser,
                setOrganisations
              )
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div style={style.auth.container}>
                  <div style={style.auth.layout}>
                    <Logo image={logo} alt="TAS Logo" />
                    <Typography source={'subtitle'}>Login</Typography>
                    <TextField
                      type="username"
                      name="username"
                      placeholder={signupData.email_label}
                      value={values.username}
                      onChange={handleChange}
                      style={{ marginBottom: 5, marginTop: 5 }}
                      source={'default'}
                    />

                    <Card
                      className="error"
                      sx={
                        errors.username && touched.username && errors.username
                          ? style.error
                          : {}
                      }
                    >
                      {errors?.username &&
                        touched?.username &&
                        errors?.username}
                    </Card>
                    <PasswordField
                      type="password"
                      name="password"
                      placeholder={signupData.password_label}
                      value={values.password}
                      onChange={handleChange}
                      style={{ marginBottom: 5 }}
                      source={'default'}
                    />
                    <Card
                      className="error"
                      sx={
                        errors.password && touched.password && errors.password
                          ? style.error
                          : {}
                      }
                    >
                      {errors?.password &&
                        touched?.password &&
                        errors?.password}
                    </Card>
                    <LoadingButton
                      Icon={IoIosArrowDropright}
                      iconStyle={{ color: color.primary }}
                      source={'primary'}
                      type={'submit'}
                      loading={loading}
                      style={{ marginBottom: 5 }}
                    >
                      Login
                    </LoadingButton>
                    {/* <Link source={'link'} onClick={() => navigate('/register')}> */}
                    {/*  {signupData.register_label} */}
                    {/* </Link> */}
                    <Link source={'link'} onClick={() => navigate('/reset')}>
                      {signupData.forgotten_label}
                    </Link>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
    </Grid>
  )
}
