import React, { useEffect, useState } from 'react'
import EditClient from './EditClient'
import ClientList from './ClientList'
import TabHeader from '../../shared/TabHeader'
import { getFormData } from '../../../resources/formData'

function Clients(props) {
  const { tab } = props
  const [edit, setEdit] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [selectedClient, setSelectedClient] = useState()
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!isEdit) {
      setSelectedClient({})
    }
  }, [])

  return edit ? (
    <>
      <TabHeader
        tabTitle={isEdit ? getFormData(tab, 'screen2Title') : 'Add Client'}
        getColor={getFormData(tab, 'color')}
      >
        <EditClient
          setEdit={(value) => {
            setEdit(value)
          }}
          isEdit={isEdit}
          selectedClient={selectedClient}
        />
      </TabHeader>
    </>
  ) : (
    <>
      <TabHeader
        tabTitle={getFormData(tab, 'title')}
        getColor={getFormData(tab, 'color')}
        showSearch
        onChangeSearch={(e) => setSearch(e.target.value)}
        searchValue={search}
      >
        <ClientList
          setEdit={(value) => {
            setEdit(value)
          }}
          setSelectedClient={(value) => setSelectedClient(value)}
          setIsEdit={(value) => setIsEdit(value)}
          isEdit={isEdit}
          search={search}
        />
      </TabHeader>
    </>
  )
}

export default Clients
