import MUIIconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import React, { useContext } from 'react'
import { ThemeContext } from './ThemeContext'

const CssButton = styled(MUIIconButton)(({ theme, source, style }) => {
  return { ...theme?.iconButton, ...theme?.iconButton?.[source], ...style }
})

const IconButton = (props) => {
  const { children } = props
  const { theme } = useContext(ThemeContext)
  return (
    <CssButton {...props} theme={theme}>
      {children}
    </CssButton>
  )
}

MUIIconButton.defaultProps = {}
export default IconButton
