import React, { useContext } from 'react'
import { ThemeContext } from './ThemeContext'
import Typography from './Typography'
import LockIcon from '@mui/icons-material/Lock'
import ToolTip from './ToolTip'
import './counter.css'

const Counter = (props) => {
  const { theme } = useContext(ThemeContext)
  const {
    style,
    source,
    text,
    Icon = LockIcon,
    count,
    color,
    toolTip = '',
  } = props
  const styles = {
    container: {
      backgroundColor: 'white',
      ...theme?.counter?.container,
      ...theme?.counter?.[source]?.container,
      ...style?.container,
      height: '100%',
      // overflow: 'auto',
      // scrollbarWidth: 'thin',
      // scrollbarColor: 'transparent transparent',
      // msOverflowStyle: 'none',
    },
    icon: {
      ...theme?.counter?.icon,
      ...theme?.counter?.[source]?.icon,
      ...style?.icon,
      color: color?.[0],
    },
    count: {
      ...theme?.counter?.count,
      ...theme?.counter?.[source]?.count,
      ...style?.count,
      color: color?.[1],
    },
    text: {
      ...theme?.counter?.text,
      ...theme?.counter?.[source]?.text,
      ...style?.text,
      color: color?.[2],
    },
    wrapper: {
      ...{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      ...theme?.counter?.wrapper,
      ...theme?.counter?.[source]?.wrapper,
      ...style?.wrapper,
    },
  }
  return (
    <div className="scrollable-container">
      <div style={styles.container} className="scrollable-content">
        <div style={styles.wrapper}>
          <Icon style={styles.icon} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography source={'h5'} style={styles.count}>
              {count}
            </Typography>
            <Typography source={'body'} style={styles.text}>
              {text}
            </Typography>
          </div>
          {toolTip ? (
            <div style={{ paddingRight: 10 }}>
              <ToolTip text={toolTip} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default Counter
