import color from './color'

const theme = {
  primary: {
    color: color.black,
    backgroundColor: color.primary2,
    '&:hover': {
      backgroundColor: color.primary2Hover,
      transition: '.5s ease',
    },
  },
  secondary: {
    color: color.white,
    backgroundColor: color.primary,
    '&:hover': {
      backgroundColor: color.primaryHover,
      transition: '.5s ease',
    },
  },
  error: {
    color: color.white,
    backgroundColor: color.error,
    '&:hover': {
      backgroundColor: color.errorHover,
      transition: '.5s ease',
    },
  },
  resetPasswordButton: {
    color: color.white,
    backgroundColor: color.secondary5,
    '&:hover': {
      backgroundColor: color.hover,
      transition: '.5s ease',
    },
  },
  menuButton: {
    height: 40,
    color: color.black,
    boxShadow: '0px 0px 6px #00000029',
    margin: 10,
    '&:hover': {
      boxShadow: '0 0 10px #B9B9B9FF',
      border: '1px',
      borderColor: color.white,
      transition: '.5s ease',
    },
    justifyContent: 'flex-start',
  },
  MuiCalendarPicker: {},
  logo: {
    width: '275px',
    height: 'auto',
    marginBottom: 20,
  },
  background: {},
  input: {},
  password: {
    icon: {
      backgroundColor: 'transparent',
      color: color.placeholder,
    },
    strengthMeter: {
      container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
      },
      bar: {
        margin: 5,
        width: 50,
        height: 4,
        borderRadius: 10,
      },
    },
  },
  checkBox: {
    color: color.primary,
    '&.Mui-checked': {
      color: color.primary,
    },
  },
  radioButton: {
    color: color.primary,
    '&.Mui-checked': {
      color: color.primary,
    },
  },
  alert: {},
  textField: {
    backgroundColor: color.white,
    borderRadius: 10,
    height: 45,
    width: '100%',
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: 5,
    },
    default: {
      borderRadius: 10,
      height: 45,
      width: '100%',
      '& input': {
        '&::placeholder': {
          color: color.placeholder,
        },
      },

      '&:hover fieldset': {
        border: `2px solid ${color.primary2}`,
        transition: '.5s ease',
      },
      '&.Mui-focused fieldset': {
        border: `3px solid ${color.primary2}`,
      },
    },
    bordered: {
      container: {
        marginRight: 10,
      },
      backgroundColor: color.white,
      borderRadius: 10,
      height: 45,
      width: '100%',
      marginTop: 10,
      boxShadow: '0px 0px 6px #00000029',
      '& input': {
        '&::placeholder': {
          color: color.placeholder,
        },
      },
      '& fieldset': {
        border: '1px',
        borderColor: color.white,
      },
      '&:hover fieldset': {
        boxShadow: '0px 0px 6px #00000029',
        border: '1px',
        borderColor: color.white,
        transition: '.5s ease',
      },
      '&.Mui-focused fieldset': {
        boxShadow: '0px 0px 6px #00000029',
        border: '1px',
        borderColor: color.white,
      },
    },
  },
  dropDown: {
    // DEFAULT START
    container: {
      width: '100%',
    },
    select: {
      control: (provided, state) => ({
        ...provided,
        boxShadow: '0px 0px 6px #00000029',
        webkitBoxShadow: '0px 0px 6px #00000029',
        backgroundColor: color.white,
        // border: state.isFocused
        //   ? `2px solid ${color.primary2}`
        //   : `1px solid ${color.black}`,
        padding: 10,
        borderRadius: 10,
        height: 45,
        overflow: 'hidden',
        // margin: state.isFocused ? -1 : 0, // counteracts the 2px border so it doesn't move the input
        '&:hover': {
          // backgroundColor: color.primary2Hover,
          // border: `2px solid ${color.grey}`,
          // margin: -1, // counteracts the 2px border so it doesn't move the input
        },
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: color.primary,
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? color.primary2 : color.white,
        color: state.isSelected ? color.black : color.black,
        padding: 10,
        '&:hover': {
          backgroundColor: color.black,
          color: color.white,
        },
      }),
      menu: (provided, state) => ({
        ...provided,
        borderRadius: '4px',
        boxShadow: 'none',
        '&:before': {
          content: '""',
          display: 'block',
          height: '8px',
        },
      }),
      multiValue: (base) => ({
        ...base,
        marginRight: 5,
        border: `1px solid ${color.black}`,
        // backgroundColor: color.secondary,
        borderRadius: 15,
        padding: '2px 5px',
      }),
    },
    // DEFAULT END
  },
  sidebar: {
    header: {
      backgroundColor: color.sidebarHeader,
      height: 75,
      marginTop: -10,
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 15,
      padding: 5,
      justifyContent: 'center',
    },
    background: {
      backgroundColor: color.sidebarBackground,
      padding: '10px 0',
      minHeight: '650px',
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
    },
  },
  dashboard: {
    header: {},
    container: {
      backgroundColor: '#FFF',
      borderRadius: '10px',
      minHeight: '650px',
      padding: 15,
    },
    background: {},
  },
  datePicker: {
    container: { display: 'flex', flexDirection: 'column', width: '100%' },
    marginTop: 10,
    '& .MuiOutlinedInput-root': {
      backgroundColor: color.white,
      borderRadius: 10,
      height: 45,
      marginBottom: 15,
      boxShadow: '0px 0px 6px #00000029',
      '& input': {
        '&::placeholder': {
          color: color.placeholder,
          // textAlign: 'left',
        },
      },
      '& fieldset': {
        border: '1px',
        borderColor: color.white,
      },
      '&:hover fieldset': {
        boxShadow: '0px 0px 6px #00000029',
        border: '1px',
        borderColor: color.white,
        transition: '.2s ease-in-out',
      },
      '&.Mui-focused fieldset': {
        boxShadow: '0px 0px 6px #00000029',
        border: '1px',
        borderColor: color.white,
      },
    },
  },
  searchBox: {
    borderRadius: 15,
    height: 30,
    width: '90%',
  },
  button: {
    borderRadius: 10,
    height: 45,
    width: '100%',
  },
  loadingButton: {
    borderRadius: 10,
    height: 45,
    width: '100%',
    justifyContent: 'space-between',
    icon: {
      width: 40,
      height: 40,
      marginRight: 2,
    },
  },
  iconButton: {
    backgroundColor: color.primary,
    color: color.white,
    ':hover': { backgroundColor: color.primaryHover },
    remove: {
      color: color.white,
      backgroundColor: color.error,
      width: 25,
      height: 25,
      '&:hover': {
        backgroundColor: color.errorHover,
        transition: '.5s ease',
      },
    },
    add: {
      width: 25,
      height: 25,
      marginLeft: 10,
    },
  },
  link: {
    color: color.primary,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: color.primary2Hover,
    },
  },
  dataTable: {
    tableContainer: {},
    table: {
      borderCollapse: 'separate',
      borderSpacing: '10px 20px',
    },
    tableHead: {
      paddingLeft: 16,
      minWidth: 170,
    },
    tableRow: {
      border: '1px',
      borderColor: color.white,
      borderRadius: 15,
      boxShadow: '0px 0px 6px #00000029',
      '-webkit-box-shadow': '0px 0px 6px #00000029',
    },
    tableCell: {
      borderBottom: 'none',
      padding: 5,
      paddingLeft: 16,
      minWidth: 170,
      textAlign: 'left',
    },
    tableBody: {},
  },
  icon: {},
  breadCrumb: {},
  dropZone: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 150,
      width: '100%',
      backgroundColor: '#B2F8FF',
    },
    image: {},
    noImage: {
      color: 'white',
    },
    icon: { color: color.primary, width: 35, height: 35 },
    title: { color: 'white' },
    label: { color: color.primary },
    filename: {},
    error: {},
  },
  dynamicList: {},
  notificationBadge: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'red',
    color: 'white',
    borderRadius: 90,
    width: 25,
    height: 25,
    // textAlign: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 13,
    marginTop: -10,
  },
  counter: {
    container: {
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      width: '100%',
      paddingLeft: 10,
      paddingRight: 10,
    },
    icon: {
      minWidth: 50,
      minHeight: 50,
      padding: 5,
    },
    count: {
      fontSize: '1.3em',
      fontFamily: 'Helvetica Neue',
      paddingRight: 5,
    },
    text: {
      textTransform: 'uppercase',
      fontSize: '.6em',
      textAlign: 'center',
      paddingRight: 5,
    },
  },

  typography: {
    fontFamily: 'Noto-Regular',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    textTransform: 'none',
    h1: {
      fontFamily: 'Noto-Bold',
      fontSize: 70,
      fontWeight: 0,
      spacing: 0,
      color: color.primary,
    },
    h2: {
      fontFamily: 'Noto-Bold',
      fontSize: 35,
      fontWeight: 'bold',
      spacing: 0,
    },
    h3: {
      fontFamily: 'Noto-Bold',
      fontSize: 30,
      fontWeight: 'bold',
      spacing: 0,
    },
    h4: {
      fontFamily: 'Noto-Bold',
      fontSize: 25,
      fontWeight: 'bold',
      spacing: 0,
    },
    h5: {
      fontSize: 14,
      fontWeight: 'bold',
      spacing: 0,
      textAlign: 'center',
      marginBottom: 5,
    },
    h6: {
      marginTop: 30,
      fontFamily: 'Noto-Bold',
      fontSize: 20,
      fontWeight: 'bold',
      spacing: 0,
    },
    h7: {
      fontFamily: 'Noto-Bold',
      fontSize: 13,
      fontWeight: '',
      spacing: 0,
    },
    body: {
      fontSize: 15,
      fontWeight: 0,
      spacing: 0,
    },
    subtitle: {
      color: color.white,
      fontSize: 20,
      fontWeight: 1000,
      spacing: 0,
    },
    subtitle2: {
      color: color.black,
      fontSize: 17,
      fontWeight: 'bold',
      spacing: 10,
      paddingTop: 15,
      paddingBottom: 5,
    },
    inputLabel: {
      color: color.primary,
      fontSize: 14,
      marginTop: 15,
    },
    sideBarHeader: {
      color: color.primary,
      fontSize: 15,
      marginBottom: 5,
    },
    sideBarHeaderBold: {
      color: color.primary,
      fontSize: 15,
      fontWeight: 'bold',
      marginBottom: 5,
    },
    paragraph: {
      fontSize: 0,
    },
    list: {
      fontSize: 12,
    },
    caption: {
      textAlign: 'center',
      fontSize: '18px',
      color: 'blue',
    },
    caption2: {
      textAlign: 'center',
      paddingTop: '30px',
      fontSize: '16px',
    },
    link: {
      color: color.primary2,
      fontSize: 13,
      fontWeight: 0,
      spacing: 0,
    },
    dashLink: {
      color: color.primary,
    },
    breadcrumb: {
      fontFamily: 'Noto-Bold',
      color: color.white,
      fontSize: 25,
      fontWeight: 'bolder',
      spacing: 0,
    },
    minicrumb: {
      color: color.white,
      fontSize: 15,
      fontWeight: 0,
      spacing: 0,
    },
    errorText: {
      color: color.white,
      fontSize: 13,
      fontWeight: 0,
      spacing: 0,
    },
    tabs: {
      fontFamily: 'Noto-Bold',

      fontSize: 20,
      fontWeight: 'bolder',
      spacing: 0,
    },
    checkbox: {
      color: color.primary,
      fontSize: 15,
      fontWeight: 0,
      spacing: 0,
    },
  },
  chartWrapper: {
    bordered: {
      container: {
        border: '1px',
        borderColor: color.white,
        borderRadius: 15,
        boxShadow: '0px 0px 6px #00000029',
        padding: 10,
        marginTop: '20px',
      },
    },
  },
  accordion: {},
  pieChart: {},
  circleChart: {},
  lineChart: {},
}

export default theme
