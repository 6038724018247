import React from 'react'
import Lottie from 'react-lottie'
import * as animationData from './loadingAnim.json'

const LoadingAnim = ({ width, height }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={height ?? 100}
        width={width ?? 100}
      />
    </div>
  )
}

export default LoadingAnim
