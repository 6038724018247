import React from 'react'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      type: 'linear', // magic
      ticks: {
        stepSize: 1,
        precision: 0,
      },
      grace: '100%',
      beginAtZero: true,
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 1,
        precision: 0,
      },
      beginAtZero: true,
    },
  },
}

const LineChart = ({ data, labels, colors }) => {
  const set = {
    labels,
    datasets: data.map((set) => {
      return {
        label: set.label,
        data: set.data,
        borderColor: colors,
        backgroundColor: colors,
      }
    }),
  }

  return (
    <div style={{ width: '100%' }}>
      <Line options={options} data={set} />
    </div>
  )
}
export default LineChart
