const formData = {
  shared: {
    path: 'Home >',
  },
  back_button_label: 'Back',
  tabs: [
    {
      key: 1,
      title: 'KPIs',
    },
    {
      key: 2,
      title: 'Users',
      screen2Title: 'Edit User',
    },
  ],
  matter_dialog: {
    title_label: 'Select a matter',
    description:
      'Your account is associated with multiples remortgages. Please select the remortgage you would like to log in',
    button_label: 'Continue',
  },
  upload_dialog: {
    title_label: 'Upload a new document',
    button_cancel_label: 'Cancel',
    button_next_label: 'Next',

    button_upload_label: 'Submit',
  },
  your_information: {
    title_label: 'TA Login',
    username_label: 'Username',
    details_label: 'My Details',
    name_label: 'Name:',
    dob_label: 'Date of Birth:',
    address_label: 'Address:',
    change_label: 'Change Password',
  },
}
const getFormData = (key, value) =>
  formData.tabs.find((t) => t?.key === key)[value]

export { formData, getFormData }
