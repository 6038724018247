import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import { FaFileUpload } from 'react-icons/fa'
import { ThemeContext } from './ThemeContext'
import Typography from './Typography'
import { FileUploader } from 'react-drag-drop-files'
import emptyImage from '../../assets/emptyimage.png'

function DropZone(props) {
  const { style } = props
  const { theme } = useContext(ThemeContext)
  const styles = { ...theme.dropZone, ...style }

  const { setFile, logo } = props
  const [fileSelected, setFileSelected] = useState(logo)
  const [error, setError] = useState(null)

  const checkFile = (file) => {
    const extensions = ['jpeg', 'png', 'gif']
    const type = file.type.split('/')[1]
    if (extensions.includes(type)) {
      setFileSelected(URL.createObjectURL(file))
      setError(null)
      createFile(file)
    } else {
      setFileSelected(null)
      setError('only jpg, png and gif')
    }
  }
  const createFile = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    setFile(formData)
  }
  const dragDropFileTypes = ['JPG', 'PNG', 'GIF', 'PDF', 'JPEG']
  return (
    <div style={{ width: 350 }}>
      <FileUploader
        handleChange={checkFile}
        name="file"
        types={dragDropFileTypes}
        classes={'drag'}
      >
        <label htmlFor="file_upload" style={styles.container}>
          <img
            key={fileSelected}
            src={fileSelected}
            style={{
              height: 150,
              width: 350,
              backgroundRepeat: 'no-repeat',
              position: 'absolute',
              'object-fit': 'contain',
            }}
            alt=" "
            onError={(ev) => {
              ev.target.src = emptyImage
            }}
          />
          <FaFileUpload style={styles.icon} />
          <Typography>Upload Logo</Typography>
          <input
            id="file_upload"
            type="file"
            onChange={(ev) => {
              // checkFile(ev.target.files[0])
            }}
            style={{ display: 'none' }}
          />
          {fileSelected && (
            <Box style={styles.filename}>
              {fileSelected ? fileSelected?.name : null}
            </Box>
          )}
          {error && <Box style={styles.error}>{error}</Box>}
        </label>
      </FileUploader>
    </div>
  )
}

export default DropZone
