import React, { useContext, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import { formData } from '../../../resources/formData'
import TabHeader from '../../shared/TabHeader'
import { ThemeContext } from '../../../ui-library/components/ThemeContext'
import Typography from '../../../ui-library/components/Typography'
import Counter from '../../../ui-library/components/Counter'
import PieChart from '../../../ui-library/components/PieChart'
import BarChart from '../../../ui-library/components/BarChart'
import RadarChart from '../../../ui-library/components/RadarChart'
import LineChart from '../../../ui-library/components/LineChart'
import {
  getFlashReports,
  getKPIS,
  getOrgContractors,
  openObservations,
} from '@northfoxgroup/frontend-api-lib'
import FlashReportTable from './FlashReportTable'
import ChartWrapper from '../../../ui-library/components/ChartWrapper'
import GaugeChart from '../../../ui-library/components/GaugeChart'
import BorderContainer from '../../../ui-library/components/BorderContainer'
import { IoIosWarning } from 'react-icons/io'
import { GiHealthNormal } from 'react-icons/gi'
import 'react-calendar/dist/Calendar.css'
import './Calendar.css'
import NotificationBadge from '../../../ui-library/components/NotificationBadge'
import Accordion from '../../../ui-library/components/Accordion'
import ToolTip from '../../../ui-library/components/ToolTip'
import ObservationsTable from './ObservationsTable'
import Dropdown from '../../../ui-library/components/Dropdown'
import Calendar from 'react-calendar'
import Button from '../../../ui-library/components/Button'
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillCloseCircle,
} from 'react-icons/ai'
import { OrganisationContext } from '../../../context/OrganisationContext'
import ErrorBoundary from '../../../utils/ErrorBoundary'
import emptyImage from '../../../assets/emptyimage.png'

function KPI(props) {
  const { tab } = props
  const date = new Date()
  const getFormData = (key, value) =>
    formData.tabs.find((t) => t?.key === key)?.[value]
  const { theme } = useContext(ThemeContext)
  const [kpiList, setKpiList] = useState()
  const [notificationBadgeNumber, setNotificationBadgeNumber] = useState(0)
  const [observationsBadgeNumber, setObservationsBadgeNumber] = useState(0)
  const [flashReports, setFlashReports] = useState()
  const [observations, setObservations] = useState()
  const [contractors, setContractors] = useState([{ label: '', value: '' }])
  const [selectedContractor, setSelectedContractor] = useState({
    id: '',
    name: 'All Contractors',
  })
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  )
  const [endDate, setEndDate] = useState(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  )
  const [dateError, setDateError] = useState(false)
  const { currentOrg } = useContext(OrganisationContext)
  const getKPI = async () => {
    const today = new Date()
    const twoYearsAgo = new Date(today)
    twoYearsAgo.setFullYear(today.getFullYear() - 2)
    const params = { startDate: twoYearsAgo, endDate: today }
    const res = await getKPIS(params)
    setKpiList(res?.data)
    setDateError(false)
  }

  const calendarKPI = async () => {
    if (startDate <= endDate) {
      const params = { startDate, endDate }
      const res = await getKPIS(params)
      setKpiList(res?.data)
      setDateError(false)
    } else {
      setDateError(true)
    }
  }
  const getObs = async (id = '') => {
    const params = id ? ',{"contractorId": "' + id + '"}' : ''
    const res = await openObservations(params)
    setObservations(res?.data)
    setObservationsBadgeNumber(res?.data?.length)
  }
  const getFR = async (id = '') => {
    const params = id ? '{"contractorId": "' + id + '"}' : '{}'
    const res = await getFlashReports(params)
    setFlashReports(res?.data)
    setNotificationBadgeNumber(res?.data?.length)
  }
  const getContractors = async () => {
    const res = await getOrgContractors()
    setContractors(res?.data)
  }

  useEffect(() => {
    if (!currentOrg) return
    getContractors()
    getFR()
    getObs()
    getKPI()
  }, [currentOrg])

  console.log(currentOrg)

  return (
    <ErrorBoundary>
      <TabHeader
        tabTitle={getFormData(tab, 'title')}
        getColor={getFormData(tab, 'color')}
      >
        <Paper style={theme?.dashboard?.container}>
          <BorderContainer>
            <Accordion
              title={
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Typography source={'h4'}>Flash Reports</Typography>
                  <NotificationBadge number={notificationBadgeNumber} />
                </div>
              }
              Icon={
                <Dropdown
                  type="contractor"
                  id={'contractor'}
                  name={'contractor'}
                  title={'Filter By Contractor'}
                  setSelected={async (value) => {
                    setSelectedContractor(value.value)
                    await getFR(value?.value?.id)
                  }}
                  selected={{
                    label: selectedContractor?.name,
                    value: selectedContractor,
                  }}
                  items={
                    contractors?.length > 0
                      ? [
                          {
                            label: 'All Contractors',
                            value: { id: '', name: 'All Contractors' },
                          },
                          ...contractors?.map((contractor) => {
                            return {
                              label: contractor?.name,
                              value: contractor,
                            }
                          }),
                        ]
                      : [{ label: 'All', value: { id: '', name: 'All' } }]
                  }
                  style={{ width: 300, alignSelf: 'flex-end' }}
                  inputLabel="'Filter By Contractor"
                />
              }
            >
              <FlashReportTable
                headers={[
                  'Date',
                  'Contractor',
                  'Area',
                  'Images',
                  'Description',
                ]}
                reports={flashReports}
              />
            </Accordion>
          </BorderContainer>
          <BorderContainer>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginBottom: 15,
                  margin: '0 auto',
                }}
              >
                <Typography source={'h5'}>{currentOrg?.name}</Typography>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {currentOrg?.logo?.fileUrl ? (
                    <img
                      src={currentOrg?.logo?.fileUrl || ''}
                      key={currentOrg?.logo?.fileUrl}
                      style={{
                        height: 220,
                        width: 320,
                        objectFit: 'contain',
                      }}
                      alt={' '}
                      onError={(ev) => {
                        ev.target.src = emptyImage
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        height: 180,
                        borderRadius: 10,
                        backgroundColor: '#e6e7e8',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 20,
                      }}
                    >
                      <Typography style={{ color: 'white' }} source={'h3'}>
                        No Logo To Display
                      </Typography>
                    </div>
                  )}
                  <div
                    style={{
                      height: 200,
                      width: 0,
                      border: '1px solid #00000029',
                      marginLeft: 10,
                    }}
                  ></div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '0 auto',
                }}
              >
                <Typography source={'h5'}>
                  Select a date range to filter KPIs by:
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                  }}
                >
                  <div className="Sample">
                    <div className="Sample__container">
                      <main className="Sample__container__content">
                        <Calendar onChange={setStartDate} value={startDate} />
                      </main>
                    </div>
                  </div>
                  <div className="Sample">
                    <div className="Sample__container">
                      <main className="Sample__container__content">
                        <Calendar onChange={setEndDate} value={endDate} />
                      </main>
                    </div>
                  </div>
                </div>
                <Button
                  onClick={() => {
                    calendarKPI()
                  }}
                  style={{ width: 200, marginBottom: 5 }}
                >
                  Show Results
                </Button>
                {dateError ? (
                  <div style={{ color: 'red' }}>
                    Start Date must be earlier than End Date
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </BorderContainer>
          {kpiList?.['Weekly Data Report Status'] && (
            <Typography
              style={{ display: 'flex', justifyContent: 'center', margin: 20 }}
              source={'h4'}
            >
              Project Overall
            </Typography>
          )}
          {kpiList?.['Weekly Data Report Status'] && (
            <BorderContainer>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Typography source={'h5'}>Weekly Data Report Status</Typography>
                <ToolTip
                  text={'Status of weekly report submission per contractor'}
                />
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                  gridGap: '20px',
                  justifyItems: 'center',
                }}
              >
                {kpiList?.['Weekly Data Report Status'] ? (
                  Object?.values(kpiList?.['Weekly Data Report Status'])?.map(
                    (item, index) => {
                      return (
                        <Counter
                          key={index}
                          color={[
                            item?.data ? '#0CE8C1' : '#FF2D0D',
                            '#0D98E8',
                            'black',
                          ]}
                          style={{ container: { width: 200 } }}
                          text={
                            Object?.keys(
                              kpiList?.['Weekly Data Report Status']
                            )[index]
                          }
                          Icon={
                            item?.data ? AiFillCheckCircle : AiFillCloseCircle
                          }
                        />
                      )
                    }
                  )
                ) : (
                  <></>
                )}
              </div>
            </BorderContainer>
          )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
              gridGap: '30px',
            }}
          >
            {kpiList?.['LTIR (12-month cycle)'] && (
              <BorderContainer>
                <ChartWrapper
                  toolTip={
                    'Lost Time Incident Rate is a standard metric that calculates the number of incidents that result in time away from work. Not all recordable incidents result in lost time. Multipliers: OSHA 200,000 hours or EUROPEAN STANDARDS 1,000,000 hours'
                  }
                  title={'LTIR'}
                  subHeading={
                    'Lost Time Injury Rate LTIR (project overall) Comparison per year'
                  }
                  Chart={BarChart}
                  data={kpiList?.['LTIR Comparison Per Year']?.data?.datasets}
                  visible={kpiList?.['LTIR Comparison Per Year']}
                  labels={kpiList?.['LTIR Comparison Per Year']?.data?.labels}
                  axis={'y'}
                  scalesRemove={{
                    y: {
                      grid: {
                        display: false,
                      },
                    },
                  }}
                />
                <ChartWrapper
                  toolTip={
                    'Lost Time Incident Rate is a standard metric that calculates the number of incidents that result in time away from work. Not all recordable incidents result in lost time. Multipliers: OSHA 200,000 hours or EUROPEAN STANDARDS 1,000,000 hours'
                  }
                  subHeading={
                    'Lost Time Injury Rate LTIR (project overall) (12-month cycle)'
                  }
                  visible={kpiList?.['LTIR (12-month cycle)']}
                  data={kpiList?.['LTIR (12-month cycle)']?.data?.[0]?.count}
                  labels={kpiList?.['LTIR (12-month cycle)']?.data?.[0]?.label}
                  max={kpiList?.['LTIR (12-month cycle)']?.data?.[0]?.max}
                  min={kpiList?.['LTIR (12-month cycle)']?.data?.[0]?.min}
                  Chart={GaugeChart}
                />
              </BorderContainer>
            )}
            {(kpiList?.['TRIR Comparison Per Year'] ||
              kpiList?.['TRIR (12-month cycle)']) && (
              <BorderContainer>
                <ChartWrapper
                  toolTip={
                    'Total Recordable Incident Rate is a standard metric that calculates the number of combined total of "Death" "Lost time injuries (LTI)", "loss of Conciousness", "Restricted Work Cases" and "Medical treatment injuries"  that result in  time of work. Multipliers: OSHA 200,000 hours or EUROPEAN STANDARDS 1,000,000 hours - For this graph is necessary to compare the information between years of project'
                  }
                  title={'TRIR'}
                  subHeading={
                    'Total Recordable Incident Rate (project overall) Comparison per year'
                  }
                  Chart={BarChart}
                  visible={kpiList?.['TRIR Comparison Per Year']}
                  data={kpiList?.['TRIR Comparison Per Year']?.data?.datasets}
                  labels={kpiList?.['TRIR Comparison Per Year']?.data?.labels}
                  axis={'y'}
                  scalesRemove={{
                    y: {
                      grid: {
                        display: false,
                      },
                    },
                  }}
                />
                <ChartWrapper
                  toolTip={
                    'Total Recordable Incident Rate is a standard metric that calculates the number of combined total of "Death" "Lost time injuries (LTI)", "loss of Conciousness", "Restricted Work Cases" and "Medical treatment injuries"  that result in  time of work. Multipliers: OSHA 200,000 hours or EUROPEAN STANDARDS 1,000,000 hours'
                  }
                  subHeading={
                    'Total Recordable Incident Rate TRIR (project overall) (12-month cycle)'
                  }
                  visible={kpiList?.['TRIR (12-month cycle)']}
                  data={kpiList?.['TRIR (12-month cycle)']?.data?.[0]?.count}
                  labels={kpiList?.['TRIR (12-month cycle)']?.data?.[0]?.label}
                  max={kpiList?.['TRIR (12-month cycle)']?.data?.[0]?.max}
                  min={kpiList?.['TRIR (12-month cycle)']?.data?.[0]?.min}
                  Chart={GaugeChart}
                />
              </BorderContainer>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              padding: 20,
            }}
          >
            {kpiList?.['Contractor X - Hours'] ||
            kpiList?.['Contractor X - Workforce Per Month'] ||
            kpiList?.['Contractor X - LTIR Rate'] ||
            kpiList?.['Contractor X - TRIR Rate'] ||
            kpiList?.['# Open Observations by Contractor'] ? (
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  textAlign: 'center',
                }}
                source={'h4'}
              >
                Contractor Summary
              </Typography>
            ) : (
              <></>
            )}
            {/* <Dropdown */}
            {/*  style={{ */}
            {/*    container: { */}
            {/*      width: 250, */}
            {/*    }, */}
            {/*  }} */}
            {/*  type="contractor" */}
            {/*  id={'contractor'} */}
            {/*  name={'contractor'} */}
            {/*  setSelected={async (value) => { */}
            {/*    selectedSummaryContractor(value.value) */}
            {/*    filterContractorSummary(value.label) */}
            {/*  }} */}
            {/*  selected={{ */}
            {/*    label: selectedContractor?.name, */}
            {/*    value: selectedContractor, */}
            {/*  }} */}
            {/*  items={ */}
            {/*    contractors?.length > 0 */}
            {/*      ? [ */}
            {/*          { label: 'All', value: { id: '', name: 'All' } }, */}
            {/*          ...contractors?.map((contractor) => { */}
            {/*            return { */}
            {/*              label: contractor?.name, */}
            {/*              value: contractor, */}
            {/*            } */}
            {/*          }), */}
            {/*        ] */}
            {/*      : [{ label: 'All', value: { id: '', name: 'All' } }] */}
            {/*  } */}
            {/*  inputLabel="'Filter By Contractor" */}
            {/* /> */}
          </div>
          <>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 0.5fr))',
                gridGap: '20px',
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography source={'h5'}>Hours</Typography>
                  <ToolTip text={'Contractor X Hours for project'} />
                </div>
                <div
                  style={{
                    height: 300,
                    overflow: 'auto',
                    boxShadow: '0px 0px 6px #00000029',
                    borderRadius: '10px',
                    padding: 10,
                  }}
                >
                  {kpiList?.['Contractor X - Hours'] &&
                    Object?.values(kpiList?.['Contractor X - Hours'])?.map(
                      (item, index) => {
                        return item.data?.labels?.[0] ? (
                          <Counter
                            key={index}
                            color={['red', '#0D98E8', 'black']}
                            text={item.data?.labels?.[0]}
                            Icon={IoIosWarning}
                            count={item.data?.datasets?.[0]?.data?.[0]}
                            style={{
                              container: { maxWidth: 130, minWidth: 130 },
                              icon: { maxWidth: 25, minWidth: 25 },
                              count: { marginBottom: 0 },
                              text: { minWidth: 95 },
                            }}
                          />
                        ) : null
                      }
                    )}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: 130,
                    minWidth: 130,
                  }}
                >
                  <Typography source={'h5'} style={{ marginLeft: 25 }}>
                    Workforce / Month
                  </Typography>
                  <ToolTip text={'Contractor X workforce per month'} />
                </div>
                <div
                  style={{
                    height: 300,
                    overflow: 'auto',
                    boxShadow: '0px 0px 6px #00000029',
                    borderRadius: '10px',
                    padding: 10,
                  }}
                >
                  {kpiList?.['Contractor X - Workforce Per Month'] &&
                    Object?.values(
                      kpiList?.['Contractor X - Workforce Per Month']
                    )?.map((item, index) => {
                      return item.data?.labels?.[0] ? (
                        <Counter
                          key={index}
                          color={['red', '#0D98E8', 'black']}
                          text={item.data?.labels?.[0]}
                          Icon={IoIosWarning}
                          count={item.data?.datasets?.[0]?.data?.[0]}
                          style={{
                            container: { maxWidth: 130, minWidth: 130 },
                            icon: { maxWidth: 25, minWidth: 25 },
                            count: { marginBottom: 0 },
                            text: { minWidth: 95 },
                          }}
                        />
                      ) : null
                    })}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography source={'h5'}>LTIR Rates</Typography>
                  <ToolTip
                    text={
                      'The LTIR rate is the Lost Time Injury Rate per contractor '
                    }
                  />
                </div>
                <div
                  style={{
                    height: 300,
                    overflow: 'auto',
                    boxShadow: '0px 0px 6px #00000029',
                    borderRadius: '10px',
                    padding: 10,
                  }}
                >
                  {kpiList?.['Contractor X - LTIR Rate'] &&
                    Object?.values(kpiList?.['Contractor X - LTIR Rate'])?.map(
                      (item, index) => {
                        return item.data?.labels?.[0] ? (
                          <Counter
                            key={index}
                            color={['red', '#0D98E8', 'black']}
                            text={item.data?.labels?.[0]}
                            Icon={IoIosWarning}
                            count={item.data?.datasets?.[0]?.data?.[0]}
                            style={{
                              container: { maxWidth: 130, minWidth: 130 },
                              icon: { maxWidth: 25, minWidth: 25 },
                              count: { marginBottom: 0 },
                              text: { minWidth: 95 },
                            }}
                          />
                        ) : null
                      }
                    )}
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography source={'h5'}>TRIR Rates</Typography>
                  <ToolTip
                    text={
                      'The TRIR rate is the Total Recordable Injury rate per contractor'
                    }
                  />
                </div>
                <div
                  style={{
                    height: 300,
                    overflow: 'auto',
                    boxShadow: '0px 0px 6px #00000029',
                    borderRadius: '10px',
                    padding: 10,
                  }}
                >
                  {' '}
                  {kpiList?.['Contractor X - TRIR Rate'] &&
                    Object?.values(kpiList?.['Contractor X - TRIR Rate'])?.map(
                      (item, index) => {
                        return item.data?.labels?.[0] ? (
                          <Counter
                            key={index}
                            color={['red', '#0D98E8', 'black']}
                            text={item.data?.labels?.[0]}
                            Icon={IoIosWarning}
                            count={item.data?.datasets?.[0]?.data?.[0]}
                            style={{
                              container: { maxWidth: 130, minWidth: 130 },
                              icon: { maxWidth: 25, minWidth: 25 },
                              count: { marginBottom: 0 },
                              text: { minWidth: 95 },
                            }}
                          />
                        ) : null
                      }
                    )}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography source={'h5'}>Open Observations</Typography>
                  </div>
                  <ToolTip
                    text={'Number of Open Observations Per Contractor'}
                  />
                </div>
                <div
                  style={{
                    height: 300,
                    overflow: 'auto',
                    boxShadow: '0px 0px 6px #00000029',
                    borderRadius: '10px',
                    padding: 10,
                  }}
                >
                  {kpiList?.['# Open Observations by Contractor'] &&
                    Object?.values(
                      kpiList?.['# Open Observations by Contractor']
                    )?.map((item, index) => {
                      return item.data?.labels?.[0] ? (
                        <Counter
                          key={index}
                          color={['red', '#0D98E8', 'black']}
                          text={item.data?.labels?.[0]}
                          Icon={IoIosWarning}
                          count={item.data?.datasets?.[0]?.data?.[0]}
                          style={{
                            container: { maxWidth: 130, minWidth: 130 },
                            icon: { maxWidth: 25, minWidth: 25 },
                            count: { marginBottom: 0 },
                          }}
                        />
                      ) : null
                    })}
                </div>
              </div>
            </div>
          </>
          {(kpiList?.['Sub Category'] ||
            kpiList?.Risk ||
            kpiList?.Category) && (
            <Typography
              style={{ display: 'flex', justifyContent: 'center', margin: 20 }}
              source={'h4'}
            >
              By Category
            </Typography>
          )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(30%, 0.5fr))',
              gridGap: '20px',
              marginBottom: 20,
            }}
          >
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
              }}
            >
              <ChartWrapper
                toolTip={
                  'Number of good practices.' +
                  'Number of Low risk observations.' +
                  'Number of Medium risk observations.' +
                  'Number of High risk observations.'
                }
                title={''}
                subHeading={'Risk'}
                source={'bordered'}
                Chart={PieChart}
                data={kpiList?.Risk?.data?.datasets?.[0]?.data}
                visible={kpiList?.Risk}
                labels={kpiList?.Risk?.data?.labels}
                keys={kpiList?.Risk?.data?.labels}
                style={{
                  chart: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                }}
              />
              <ChartWrapper
                toolTip={
                  'Number of Physical agent observations.' +
                  'Number of Biological observations.' +
                  'Number of Chemical Observations.' +
                  'Number of Physiological Observations.'
                }
                title={''}
                visible={kpiList?.Category}
                subHeading={'Category'}
                source={'bordered'}
                Chart={PieChart}
                data={kpiList?.Category?.data?.datasets?.[0]?.data}
                labels={kpiList?.Category?.data?.labels}
                keys={kpiList?.Category?.data?.labels}
              />
            </div>
            <ChartWrapper
              toolTip={'Total of number observation per subcategory'}
              title={''}
              subHeading={'Sub Category'}
              source={'bordered'}
              Chart={PieChart}
              data={kpiList?.['Sub Category']?.data?.datasets?.[0]?.data}
              visible={kpiList?.['Sub Category']}
              labels={kpiList?.['Sub Category']?.data?.labels}
              keys={kpiList?.['Sub Category']?.data?.labels}
              style={{
                container: {
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                },
              }}
            />
          </div>
          <BorderContainer>
            <Accordion
              title={
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Typography source={'h4'}>Open Observations</Typography>
                  <NotificationBadge number={observationsBadgeNumber} />
                </div>
              }
              Icon={
                <Dropdown
                  style={{ alignSelf: 'flex-end' }}
                  type="contractor"
                  id={'contractor'}
                  name={'contractor'}
                  title={'Filter By Contractor'}
                  setSelected={async (value) => {
                    setSelectedContractor(value.value)
                    await getObs(value?.value?.id)
                  }}
                  selected={{
                    label: selectedContractor?.name,
                    value: selectedContractor,
                  }}
                  items={
                    contractors?.length > 0
                      ? [
                          { label: 'All', value: { id: '', name: 'All' } },
                          ...contractors?.map((contractor) => {
                            return {
                              label: contractor?.name,
                              value: contractor,
                            }
                          }),
                        ]
                      : [{ label: 'All', value: { id: '', name: 'All' } }]
                  }
                  inputLabel="'Filter By Contractor"
                />
              }
            >
              <ObservationsTable
                headers={[
                  'Date',
                  'Contractor',
                  'Category',
                  'Risk',
                  'Images',
                  'Description',
                ]}
                observations={observations}
              />
            </Accordion>
          </BorderContainer>
          {kpiList?.['Hours & Workers'] && (
            <Typography
              style={{ display: 'flex', justifyContent: 'center', margin: 20 }}
              source={'h4'}
            >
              Hours & Workers
              <ToolTip
                text={
                  'The total sum of the hours worked by personnel on the project per month'
                }
              />
            </Typography>
          )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(15%, 0.5fr))',
              gridGap: '20px',
            }}
          >
            {kpiList?.['Hours & Workers']?.data?.datasets?.map(
              (item, index) => {
                return (
                  <Counter
                    key={index}
                    color={['green', '#0D98E8', 'black']}
                    count={item?.data?.[index]}
                    text={'Hours Worked Per Month'}
                    Icon={AiFillClockCircle}
                  />
                )
              }
            )}
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(45%, 1fr))',
              gridGap: '20px',
            }}
          >
            <ChartWrapper
              title={''}
              subHeading={'Number of Workers in Total'}
              toolTip={
                'Number of Workers in total - line graph showing fluctuations per month'
              }
              source={'bordered'}
              Chart={LineChart}
              visible={kpiList?.['# Workers']}
              data={kpiList?.['# Workers']?.data?.datasets}
              labels={kpiList?.['# Workers']?.data?.labels}
            />
            <ChartWrapper
              title={''}
              subHeading={
                'Number of worked hours in total (entire project total) Shown on monthly chart'
              }
              toolTip={
                'Number of worked hours in total (entire project total) Shown on monthly chart'
              }
              source={'bordered'}
              Chart={LineChart}
              data={
                kpiList?.['Total Worked Hours (Entire Project)']?.data?.datasets
              }
              visible={kpiList?.['Total Worked Hours (Entire Project)']}
              labels={
                kpiList?.['Total Worked Hours (Entire Project)']?.data?.labels
              }
            />
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(45%, 1fr))',
              gridGap: '20px',
            }}
          >
            <ChartWrapper
              subHeading={'Number of worked hours per year in total'}
              toolTip={'Number of worked hours per year in total'}
              source={'bordered'}
              Chart={BarChart}
              visible={kpiList?.['# Worked Hours / Year']}
              data={kpiList?.['# Worked Hours / Year']?.data?.datasets}
              labels={kpiList?.['# Worked Hours / Year']?.data?.labels}
              axis={'y'}
              scalesRemove={{
                y: {
                  grid: {
                    display: false,
                  },
                },
              }}
            />
            <ChartWrapper
              subHeading={'Number of Lost-Hours per month'}
              Chart={PieChart}
              visible={kpiList?.['# Lost Hours / Month']}
              data={
                kpiList?.['# Lost Hours / Month']?.data?.datasets?.[0]?.data
              }
              labels={kpiList?.['# Lost Hours / Month']?.data?.labels}
              keys={kpiList?.['# Lost Hours / Month']?.data?.labels}
              source={'bordered'}
              toolTip={'Number of Lost hours Per Month'}
            />
          </div>
          <ChartWrapper
            subHeading={'Number of hours lost by contractor'}
            toolTip={'Number of production hours lost per contractor'}
            source={'bordered'}
            Chart={BarChart}
            visible={kpiList?.['# Hours Lost by Contractor']}
            data={kpiList?.['# Hours Lost by Contractor']?.data?.datasets}
            labels={kpiList?.['# Hours Lost by Contractor']?.data?.labels}
            axis={'y'}
            scalesRemove={{
              y: {
                grid: {
                  display: false,
                },
              },
            }}
          />
          {/* <div */}
          {/*  style={{ */}
          {/*    display: 'grid', */}
          {/*    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', */}
          {/*    gridGap: '20px', */}
          {/*  }} */}
          {/* > */}
          {/*  <ChartWrapper */}
          {/*    subHeading={'Number of Lost-Hours by Area'} */}
          {/*    Chart={PieChart} */}
          {/*    data={kpiList?.['# Lost Hours / Area']?.data?.datasets?.[0].data} */}
          {/*    labels={kpiList?.['# Lost Hours / Area']?.data?.labels} */}
          {/*    keys={kpiList?.['# Lost Hours / Area']?.data?.labels} */}
          {/*    source={'bordered'} */}
          {/*    toolTip={'Number of Lost-Hours by Area'} */}
          {/*  /> */}
          {/*  <ChartWrapper */}
          {/*    subHeading={'Number of Lost-Hours by Reason'} */}
          {/*    Chart={PieChart} */}
          {/*    data={ */}
          {/*      kpiList?.['# Lost Hours / Reason']?.data?.datasets?.[0].data */}
          {/*    } */}
          {/*    labels={kpiList?.['# Lost Hours / Reason']?.data?.labels} */}
          {/*    keys={kpiList?.['# Lost Hours / Reason']?.data?.labels} */}
          {/*    source={'bordered'} */}
          {/*    toolTip={'Number of Lost-Hours by Reason'} */}
          {/*  /> */}
          {/* </div> */}
          <ChartWrapper
            subHeading={'Number of worked hours vs number of Observations'}
            toolTip={'Number of worked hours vs number of Observations'}
            source={'bordered'}
            Chart={LineChart}
            visible={kpiList?.['# Worked Hours vs # Observations']}
            data={kpiList?.['# Worked Hours vs # Observations']?.data?.datasets}
            labels={kpiList?.['# Worked Hours vs # Observations']?.data?.labels}
          />
          <ChartWrapper
            subHeading={
              'Number of workers on site per month vs number of accidents per month'
            }
            toolTip={
              'Number of workers on site per month vs number of accidents per month.'
            }
            source={'bordered'}
            Chart={LineChart}
            visible={kpiList?.['# Workers on Site vs # of Accidents']}
            data={
              kpiList?.['# Workers on Site vs # of Accidents']?.data?.datasets
            }
            labels={
              kpiList?.['# Workers on Site vs # of Accidents']?.data?.labels
            }
          />
          {(kpiList?.['# Fatality Cases'] ||
            kpiList?.['# Job Transfer Days'] ||
            kpiList?.['# First Aid Injuries'] ||
            kpiList?.['# Lost Time Injury Cases'] ||
            kpiList?.['# Restricted Work / Job Transfer Cases'] ||
            kpiList?.['# Property Damage Events'] ||
            kpiList?.['# Loss of Consciousness Cases'] ||
            kpiList?.['# Near Misses'] ||
            kpiList?.['# Medical Treatment Injuries']) && (
            <Typography
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: 20,
              }}
              source={'h4'}
            >
              Adverse Events & Injuries
            </Typography>
          )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
              justifyItems: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ width: 300 }}>
              {kpiList?.['# Near Misses']?.data?.datasets?.map(
                (item, index) => {
                  return (
                    <Counter
                      key={index}
                      color={['red', '#0D98E8', 'black']}
                      text={kpiList?.['# Near Misses']?.data?.labels[index]}
                      Icon={GiHealthNormal}
                      count={item.data?.[index]}
                      toolTip={'Total # Near Misses for all contractors'}
                    />
                  )
                }
              )}
              {kpiList?.['# Medical Treatment Injuries']?.data?.datasets?.map(
                (item, index) => {
                  return (
                    <Counter
                      key={index}
                      color={['red', '#0D98E8', 'black']}
                      text={
                        kpiList?.['# Medical Treatment Injuries']?.data?.labels[
                          index
                        ]
                      }
                      Icon={GiHealthNormal}
                      count={item.data?.[index]}
                      toolTip={
                        'Total # Medical Treatment Injuries for all contractors'
                      }
                    />
                  )
                }
              )}
              {kpiList?.['# Loss of Consciousness Cases']?.data?.datasets?.map(
                (item, index) => {
                  return (
                    <Counter
                      key={index}
                      color={['red', '#0D98E8', 'black']}
                      text={
                        kpiList?.['# Loss of Consciousness Cases']?.data
                          ?.labels[index]
                      }
                      Icon={GiHealthNormal}
                      count={item.data?.[index]}
                      toolTip={
                        'Number of accidents or events which resulted in the loss of conciousness'
                      }
                    />
                  )
                }
              )}
            </div>
            <div style={{ width: 300 }}>
              {kpiList?.['# Property Damage Events']?.data?.datasets?.map(
                (item, index) => {
                  return (
                    <Counter
                      key={index}
                      color={['red', '#0D98E8', 'black']}
                      text={
                        kpiList?.['# Property Damage Events']?.data?.labels[
                          index
                        ]
                      }
                      Icon={GiHealthNormal}
                      count={item.data?.[index]}
                      toolTip={
                        'Total # Property Damage Events for all contractors'
                      }
                    />
                  )
                }
              )}
              {kpiList?.[
                '# Restricted Work / Job Transfer Cases'
              ]?.data?.datasets?.map((item, index) => {
                return (
                  <Counter
                    key={index}
                    color={['red', '#0D98E8', 'black']}
                    text={
                      kpiList?.['# Restricted Work / Job Transfer Cases']?.data
                        ?.labels[index]
                    }
                    Icon={GiHealthNormal}
                    count={item.data?.[index]}
                    toolTip={
                      'The number of cases which required persons to be transferred to other jobs as a result of an accident '
                    }
                  />
                )
              })}
              {kpiList?.['# Lost Time Injury Cases']?.data?.datasets?.map(
                (item, index) => {
                  return (
                    <Counter
                      key={index}
                      color={['red', '#0D98E8', 'black']}
                      text={
                        kpiList?.['# Lost Time Injury Cases']?.data?.labels[
                          index
                        ]
                      }
                      Icon={GiHealthNormal}
                      count={item.data?.[index]}
                      toolTip={
                        'Total # Lost Time Injury Cases for all contractors'
                      }
                    />
                  )
                }
              )}
            </div>
            <div style={{ width: 300 }}>
              {kpiList?.['# First Aid Injuries']?.data?.datasets?.map(
                (item, index) => {
                  return (
                    <Counter
                      key={index}
                      color={['red', '#0D98E8', 'black']}
                      text={
                        kpiList?.['# First Aid Injuries']?.data?.labels[index]
                      }
                      Icon={GiHealthNormal}
                      count={item.data?.[index]}
                      toolTip={'The number of injuries classified as first aid'}
                    />
                  )
                }
              )}
              {kpiList?.['# Job Transfer Days']?.data?.datasets?.map(
                (item, index) => {
                  return (
                    <Counter
                      key={index}
                      color={['red', '#0D98E8', 'black']}
                      text={
                        kpiList?.['# Job Transfer Days']?.data?.labels[index]
                      }
                      Icon={GiHealthNormal}
                      count={item.data?.[index]}
                      toolTip={
                        'The total number of days in which persons were required to be trnasferred to another job as a result of an accident '
                      }
                    />
                  )
                }
              )}
              {kpiList?.['# Fatality Cases']?.data?.datasets?.map(
                (item, index) => {
                  return (
                    <Counter
                      key={index}
                      color={['red', '#0D98E8', 'black']}
                      text={kpiList?.['# Fatality Cases']?.data?.labels[index]}
                      Icon={GiHealthNormal}
                      count={item.data?.[index]}
                      toolTip={
                        'The number of deaths / fatalities as a result of an accident'
                      }
                    />
                  )
                }
              )}
            </div>
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(430px, 1fr))',
              gridGap: '30px',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <ChartWrapper
              toolTip={
                'It is an Loss of Primary Containment (LOPC) with consequence. It is an unplanned or uncontrolled release of any material, including non-toxic and non-flammable materials, from a process that results in consequences as listed, per the API 754 Guide. A Tier 1 Process Safety event may involve significant actual or potential impacts. As an approximation Tier 2 events are roughly an order of magnitude lower impact than Tier 1 Process Safety Events. It could be rated in 200,000 hour or 1,000,000.00 hours. ' +
                'Tier 3 Process Safety Events (T-3 PSEs) are company or site defined performance indicators that provide information about the strength (or lack thereof) of barriers and weaknesses in equipment and hazard control systems. A Tier 3 indicator represents a challenge to the barrier system that progressed along a path to harm but stopped short of a Tier 1 or 2 LOPC consequence. Examples of Tier 3 indicators include:\n' +
                'Safe Operating Limit Excursions (i.e., a process parameter deviation hat exceeds the safe operating limit applicable to the phase of operation);\n' +
                'Primary Containment Inspection or testing Results Outside Acceptable Limits (i.e., an inspection or test finding that indicates primary containment equipment has been operated outside acceptable limits);\n' +
                'Demands on Safety Systems (EXCLUDING intentional activations during periodic testing or manual activation as part of normal shutdown process; SIS activation configured for equipment protection with no LOPC consequence);\n' +
                'Other LOPC events (i.e., a loss of primary containment with a consequence less than Tier 2 events).'
              }
              title={''}
              subHeading={'Process Safety Events'}
              source={'bordered'}
              Chart={PieChart}
              visible={kpiList?.['Process Safety Events']}
              data={
                kpiList?.['Process Safety Events']?.data?.datasets?.[0]?.data
              }
              labels={kpiList?.['Process Safety Events']?.data?.labels}
              keys={kpiList?.['Process Safety Events']?.data?.labels}
            />
            <ChartWrapper
              title={''}
              subHeading={'Near Miss Incident Rate'}
              toolTip={
                'Near Miss Incident Rate is a internal metric that calculates the number of near misses in work in a period of time. Miltipliers: OSHA 200,000 hours or EUROPEAN STANDARDS 1,000,000 hours or 100.000 hours'
              }
              source={'bordered'}
              Chart={BarChart}
              visible={kpiList?.['Near Miss Incident Rate']}
              data={kpiList?.['Near Miss Incident Rate']?.data?.datasets}
              labels={kpiList?.['Near Miss Incident Rate']?.data?.labels}
              axis={'y'}
              scalesRemove={{
                y: {
                  grid: {
                    display: false,
                  },
                },
              }}
            />
          </div>
          {(kpiList?.['Total # RIDDOR Cases'] ||
            kpiList?.['# RIDDOR Occuptional Illness Cases'] ||
            kpiList?.['# RIDDOR Specified Injuries'] ||
            kpiList?.['# 3-day RIDDOR Injuries'] ||
            kpiList?.['# RIDDOR Dangerous Occurences'] ||
            kpiList?.['# 7-day RIDDOR Injuries'] ||
            kpiList?.['Comparison RIDDOR Rate / Year']) && (
            <>
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 20,
                }}
                source={'h4'}
              >
                RIDDOR
              </Typography>
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 20,
                }}
                source={'h5'}
              >
                Summary
              </Typography>
            </>
          )}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
              justifyItems: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ width: 300 }}>
              {kpiList?.['Total # RIDDOR Cases']?.data?.datasets?.map(
                (item, index) => {
                  return (
                    <Counter
                      key={index}
                      color={['red', '#0D98E8', 'black']}
                      text={
                        kpiList?.['Total # RIDDOR Cases']?.data?.labels[index]
                      }
                      Icon={GiHealthNormal}
                      count={item.data?.[index]}
                      toolTip={'Total # RIDDOR Cases for all contractors'}
                    />
                  )
                }
              )}
              {kpiList?.[
                '# RIDDOR Occuptional Illness Cases'
              ]?.data?.datasets?.map((item, index) => {
                return (
                  <Counter
                    key={index}
                    color={['red', '#0D98E8', 'black']}
                    text={
                      kpiList?.['# RIDDOR Occuptional Illness Cases']?.data
                        ?.labels[index]
                    }
                    Icon={GiHealthNormal}
                    count={item.data?.[index]}
                    toolTip={
                      'Total # RIDDOR Occuptional Illness Cases for all contractors'
                    }
                  />
                )
              })}
            </div>
            <div style={{ width: 300 }}>
              {kpiList?.['# RIDDOR Specified Injuries']?.data?.datasets?.map(
                (item, index) => {
                  return (
                    <Counter
                      key={index}
                      color={['red', '#0D98E8', 'black']}
                      text={
                        kpiList?.['# RIDDOR Specified Injuries']?.data?.labels[
                          index
                        ]
                      }
                      Icon={GiHealthNormal}
                      count={item.data?.[index]}
                      toolTip={
                        'The number of injuries that meet the classification of a specified injury under RIDDOR'
                      }
                    />
                  )
                }
              )}
              {kpiList?.['# RIDDOR Major Injuries']?.data?.datasets?.map(
                (item, index) => {
                  return (
                    <Counter
                      key={index}
                      color={['red', '#0D98E8', 'black']}
                      text={
                        kpiList?.['# RIDDOR Major Injuries']?.data?.labels[
                          index
                        ]
                      }
                      Icon={GiHealthNormal}
                      count={item.data?.[index]}
                      toolTip={
                        'Total # RIDDOR Major Injuries for all contractors'
                      }
                    />
                  )
                }
              )}
            </div>
            <div style={{ width: 300 }}>
              {kpiList?.['# RIDDOR Dangerous Occurences']?.data?.datasets?.map(
                (item, index) => {
                  return (
                    <Counter
                      key={index}
                      color={['red', '#0D98E8', 'black']}
                      text={
                        kpiList?.['# RIDDOR Dangerous Occurences']?.data
                          ?.labels[index]
                      }
                      Icon={GiHealthNormal}
                      count={item.data?.[index]}
                      toolTip={
                        'The number of injuries that meet the classification of a dangerous occurrence under RIDDOR'
                      }
                    />
                  )
                }
              )}
              {kpiList?.['# 7-day RIDDOR Injuries']?.data?.datasets?.map(
                (item, index) => {
                  return (
                    <Counter
                      key={index}
                      color={['red', '#0D98E8', 'black']}
                      text={
                        kpiList?.['# 7-day RIDDOR Injuries']?.data?.labels[
                          index
                        ]
                      }
                      Icon={GiHealthNormal}
                      count={item.data?.[index]}
                      toolTip={
                        'Total # 7-day RIDDOR Injuries for all contractors'
                      }
                    />
                  )
                }
              )}
            </div>
          </div>
          <ChartWrapper
            subHeading={'Comparison RIDDOR rate per year'}
            toolTip={'Total number of RIDDOR events per year '}
            source={'bordered'}
            Chart={LineChart}
            visible={kpiList?.['Comparison RIDDOR Rate / Year']}
            data={kpiList?.['Comparison RIDDOR Rate / Year']?.data?.datasets}
            labels={kpiList?.['Comparison RIDDOR Rate / Year']?.data?.labels}
          />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(40%, 1fr))',
              gridGap: '20px',
            }}
          >
            <div>
              {kpiList?.['RIDDOR Rate (UK) - Incident Rate'] && (
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 20,
                  }}
                  source={'h4'}
                >
                  RIDDOR Rate
                </Typography>
              )}
              <ChartWrapper
                subHeading={
                  'RIDDOR Rate (UK) (Per 12-month-cycle) - Incident Rate'
                }
                toolTip={
                  'Incident rate for RIDDOR Specified Injury for  100,000 workers '
                }
                source={'bordered'}
                Chart={RadarChart}
                keys={
                  kpiList?.['RIDDOR Rate (UK) - Incident Rate']?.data?.labels
                }
                style={{
                  container: {
                    minHeight: 350,
                  },
                  chart: {
                    height: 375,
                    width: 375,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  },
                }}
                visible={kpiList?.['RIDDOR Rate (UK) - Incident Rate']}
                data={
                  kpiList?.['RIDDOR Rate (UK) - Incident Rate']?.data?.datasets
                }
                labels={
                  kpiList?.['RIDDOR Rate (UK) - Incident Rate']?.data?.labels
                }
              />
              {kpiList?.[
                'RIDDOR Dangerous Occurrence Rate (UK) - Incident Rate'
              ] && (
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 20,
                  }}
                  source={'h4'}
                >
                  Dangerous Occurrence
                </Typography>
              )}
              <ChartWrapper
                subHeading={
                  'RIDDOR Dangerous Occurrence Rate (UK) (Per 12-month-cycle) - Incident Rate'
                }
                toolTip={
                  'Incident rate for RIDDOR Dangerous Occurrence for  100,000 workers'
                }
                source={'bordered'}
                Chart={RadarChart}
                keys={
                  kpiList?.[
                    'RIDDOR Dangerous Occurrence Rate (UK) - Incident Rate'
                  ]?.data?.labels
                }
                style={{
                  container: {
                    minHeight: 350,
                  },
                  chart: {
                    height: 375,
                    width: 375,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  },
                }}
                labels={
                  kpiList?.[
                    'RIDDOR Dangerous Occurrence Rate (UK) - Incident Rate'
                  ]?.data?.labels
                }
                visible={
                  kpiList?.[
                    'RIDDOR Dangerous Occurrence Rate (UK) - Incident Rate'
                  ]
                }
                data={
                  kpiList?.[
                    'RIDDOR Dangerous Occurrence Rate (UK) - Incident Rate'
                  ]?.data?.datasets
                }
              />
              {kpiList?.['RIDDOR 7 day Injury Rate (UK) - Incident Rate'] && (
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 20,
                  }}
                  source={'h4'}
                >
                  RIDDOR Major Injuries
                </Typography>
              )}
              <ChartWrapper
                subHeading={'RIDDOR Major Injuries Rate'}
                toolTip={'Major Injury Rate for 100,000 workers'}
                source={'bordered'}
                style={{
                  container: {
                    minHeight: 350,
                  },
                  chart: {
                    height: 375,
                    width: 375,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  },
                }}
                labels={kpiList?.['RIDDOR Major Injuries Rate']?.data?.labels}
                keys={kpiList?.['RIDDOR Major Injuries Rate']?.data?.labels}
                visible={kpiList?.['RIDDOR Major Injuries Rate']}
                data={kpiList?.['RIDDOR Major Injuries Rate']?.data?.datasets}
                Chart={RadarChart}
              />
            </div>
            <div>
              {kpiList?.['RIDDOR Specified Injury (UK) - Incident Rate'] && (
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 20,
                  }}
                  source={'h4'}
                >
                  Specific Injury
                </Typography>
              )}
              <ChartWrapper
                style={{
                  container: {
                    minHeight: 350,
                  },
                  chart: {
                    height: 375,
                    width: 375,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  },
                }}
                subHeading={
                  'RIDDOR Specified Injury (UK) (Per 12-month-cycle) - Incident Rate'
                }
                toolTip={
                  'Incident rate for RIDDOR Specified Injury for  100,000 workers '
                }
                source={'bordered'}
                type={'radar'}
                Chart={RadarChart}
                labels={
                  kpiList?.['RIDDOR Specified Injury (UK) - Incident Rate']
                    ?.data?.labels
                }
                visible={
                  kpiList?.['RIDDOR Specified Injury (UK) - Incident Rate']
                }
                data={
                  kpiList?.['RIDDOR Specified Injury (UK) - Incident Rate']
                    ?.data?.datasets
                }
                keys={
                  kpiList?.['RIDDOR Specified Injury (UK) - Incident Rate']
                    ?.data?.labels
                }
              />
              {kpiList?.[
                'RIDDOR Occupational illness (UK) - Incident Rate'
              ] && (
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 20,
                  }}
                  source={'h4'}
                >
                  Occupational Illness
                </Typography>
              )}
              <ChartWrapper
                subHeading={
                  'RIDDOR Occupational illness (UK) (Per 12-month-cycle) - Incident Rate'
                }
                toolTip={
                  'Incident rate for RIDDOR Occupational illness for 100,000 workers'
                }
                source={'bordered'}
                Chart={RadarChart}
                style={{
                  container: {
                    minHeight: 350,
                  },
                  chart: {
                    height: 375,
                    width: 375,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  },
                }}
                labels={
                  kpiList?.['RIDDOR Occupational illness (UK) - Incident Rate']
                    ?.data?.labels
                }
                visible={
                  kpiList?.['RIDDOR Occupational illness (UK) - Incident Rate']
                }
                data={
                  kpiList?.['RIDDOR Occupational illness (UK) - Incident Rate']
                    ?.data?.datasets
                }
                keys={
                  kpiList?.['RIDDOR Occupational illness (UK) - Incident Rate']
                    ?.data?.labels
                }
              />

              {kpiList?.['RIDDOR 7 day Injury Rate (UK) - Incident Rate'] && (
                <Typography
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 20,
                  }}
                  source={'h4'}
                >
                  7 Days Injury Rate
                </Typography>
              )}
              <ChartWrapper
                subHeading={
                  'RIDDOR 7 days Injury Rate (UK) (Per 12-month-cycle) - Incident Rate'
                }
                toolTip={
                  'Incident rate for RIDDOR 7 day Injury for  100,000 workers. This will help identify accidents that result in employees being away from work, or unable to perform their normal work duties, for more than seven consecutive days as the result of their injury. This seven day period does not include the day of the accident, but does include weekends and rest days. '
                }
                source={'bordered'}
                style={{
                  container: {
                    minHeight: 350,
                  },
                  chart: {
                    height: 375,
                    width: 375,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  },
                }}
                labels={
                  kpiList?.['RIDDOR 7 day Injury Rate (UK) - Incident Rate']
                    ?.data?.labels
                }
                keys={
                  kpiList?.['RIDDOR 7 day Injury Rate (UK) - Incident Rate']
                    ?.data?.labels
                }
                visible={
                  kpiList?.['RIDDOR 7 day Injury Rate (UK) - Incident Rate']
                }
                data={
                  kpiList?.['RIDDOR 7 day Injury Rate (UK) - Incident Rate']
                    ?.data?.datasets
                }
                Chart={RadarChart}
              />
            </div>
          </div>
          {kpiList?.['OSHA DART Comparison / Year'] && (
            <Typography
              style={{ display: 'flex', justifyContent: 'center', margin: 20 }}
              source={'h4'}
            >
              OSHA DART Rate
            </Typography>
          )}
          <ChartWrapper
            subHeading={'OSHA DART Rate'}
            toolTip={
              "The Days Away, Restricted, or Transferred (DART) Rate is designed to track any OSHA recordable workplace injury or illness that results in time away from work, restricted job roles, or an employee's permanent transfer to a new position. The 200,000 represents 100 employees working 40 hours a week for 50 weeks during a calendar year. "
            }
            source={'bordered'}
            visible={kpiList?.['OSHA DART Rate']}
            data={kpiList?.['OSHA DART Rate']?.data?.[0]?.count}
            max={kpiList?.['OSHA DART Rate']?.data?.[0]?.max}
            min={kpiList?.['OSHA DART Rate']?.data?.[0]?.min}
            labels={[1]}
            Chart={GaugeChart}
          />
          <ChartWrapper
            subHeading={'OSHA DART Comparison per year'}
            toolTip={'Total number of OSHA Dart events per year '}
            source={'bordered'}
            Chart={RadarChart}
            keys={kpiList?.['OSHA DART Comparison / Year']?.data?.labels}
            visible={kpiList?.['OSHA DART Comparison / Year']}
            data={kpiList?.['OSHA DART Comparison / Year']?.data?.datasets}
            labels={kpiList?.['OSHA DART Comparison / Year']?.data?.labels}
            width={350}
            style={{
              container: {
                display: 'flex',
                flexDirection: 'column',
                minHeight: 350,
              },
              chart: {
                alignSelf: 'center',
              },
            }}
          />
        </Paper>
      </TabHeader>
    </ErrorBoundary>
  )
}

export default KPI
