import React, { useState, forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'

import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import Button from '@mui/material/Button'

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
))
Alert.displayName = 'Alert'

export default function Alerts(props) {
  const { alert, setAlert } = props
  const navigate = useNavigate()

  const [vertical] = useState('top')
  const [horizontal] = useState('center')

  const action = (
    <Button size="small" onClick={() => navigate('/reset')}>
      RESET
    </Button>
  )

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setAlert({
      open: false,
      severity: '',
      message: '',
      action: false,
    })
  }

  return (
    <Snackbar
      open={alert.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert
        action={alert.action ? action : ''}
        onClose={handleClose}
        severity={alert.severity}
      >
        {alert.message}
      </Alert>
    </Snackbar>
  )
}
