import React, { useState } from 'react'
import Button from './components/Button'
import LockIcon from '@mui/icons-material/Lock'
import LoadingButton from './components/LoadingButton'
import IconButton from './components/IconButton'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from './components/TextField'
import DynamicList from './components/DynamicList'
import Logo from './components/Logo'
import logo from '../assets/dark-logo.png'
import CheckboxList from './components/CheckboxList'
import Dropdown from './components/Dropdown'
import DateSelect from './components/DateSelect'
import Link from './components/Link'
import DropZone from './components/DropZone'
import PasswordField from './components/PasswordField'
import SearchField from './components/SearchField'
import Typography from './components/Typography'
import BreadCrumbs from './components/BreadCrumbs'
import Counter from './components/Counter'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const KitchenSink = () => {
  const [loading, setLoading] = useState(false)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 20,
          width: '50vw',
        }}
      >
        <div>
          <Typography type={'h1'}>Wireframe</Typography>
          <Typography type={'h2'}>Wireframe</Typography>
          <Typography type={'h3'}>Wireframe</Typography>
          <Typography type={'h4'}>Wireframe</Typography>
          <Typography type={'h5'}>Wireframe</Typography>
          <Typography type={'h6'}>Wireframe</Typography>
        </div>
        <div>
          <Button startIcon={<LockIcon />}>Wireframe</Button>
          <LoadingButton
            endIcon={<LockIcon />}
            loading={loading}
            onClick={() => setLoading(true)}
          >
            Loading
          </LoadingButton>
          <IconButton>
            <LockIcon />
          </IconButton>
        </div>
        <TextField />
        <PasswordField />
        <SearchField icon placeholder={'Search'} />
        <DynamicList />
        <Logo />
        <CheckboxList items={[1, 2, 3]} />
        <Dropdown items={[0, 1, 2, 3, 4]} Icon={ArrowDropDownIcon} />
        <DateSelect />
        <Link>Link</Link>
        <BreadCrumbs />
        <DropZone />
        <Counter text={'Placeholder'} Icon={LockIcon} count={3} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 20,
          width: '50vw',
        }}
      >
        <CssBaseline />
        <div>
          <Typography type={'h1'}>TA</Typography>
          <Typography type={'h2'}>TA</Typography>
          <Typography type={'h3'}>TA</Typography>
          <Typography type={'h4'}>TA</Typography>
          <Typography type={'h5'}>TA</Typography>
          <Typography type={'h6'}>TA</Typography>
        </div>
        <div>
          <Button type={'primary'} startIcon={<LockIcon />}>
            Primary 1
          </Button>
          <Button type={'secondary'} endIcon={<LockIcon />}>
            Secondary
          </Button>
          <Button type={'error'}>Tertiary</Button>
          <LoadingButton
            color={'secondary'}
            endIcon={<LockIcon />}
            loading={loading}
            onClick={() => setLoading(true)}
          >
            Loading
          </LoadingButton>
          <IconButton>
            <LockIcon />
          </IconButton>
          <Button style={{ backgroundColor: 'pink', borderRadius: 1000 }}>
            Unique Override Theme
          </Button>
        </div>
        <TextField color={'primary'} />
        <PasswordField icon />
        <SearchField icon placeholder={'Search'} />
        <DynamicList />
        <Logo image={logo} />
        <CheckboxList items={[1, 2, 3]} />
        <Dropdown items={[0, 1, 2, 3, 4]} Icon={ArrowDropDownIcon} />
        <DateSelect />
        <Link>Link</Link>
        <BreadCrumbs />
        <DropZone />
        <Counter
          text={'Place holder testing left to new line'}
          Icon={LockIcon}
          count={32}
        />
      </div>
    </div>
  )
}

export default KitchenSink
