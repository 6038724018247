import React, { useEffect, useState } from 'react'
import Gauge from 'react-gauge-chart'
import Typography from './Typography'
import color from '../styles/color'

const GaugeChart = ({ data, max, min }) => {
  const [percentageDisplay, setPercentageDisplay] = useState(0)
  const [percentageGauge, setPercentageGauge] = useState(0)
  useEffect(() => {
    let percentage = ((data - min) / (max - min)) * 100
    percentage = Math.min(Math.max(percentage, 0), 100)
    setPercentageDisplay(data)
    setPercentageGauge(percentage)
  }, [data, max, min])
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Gauge
        nrOfLevels={1}
        arcPadding={0}
        cornerRadius={0}
        percent={percentageGauge / 100}
        arcsLength={[0.3, 0.5, 0.2]}
        colors={['#0D98E8', '#00E7FF', '#FF1896']}
        hideText
      />
      <div style={{ position: 'absolute', textAlign: 'center' }}>
        <Typography source={'h3'} style={{ color: color.primary }}>
          {percentageDisplay}
        </Typography>
        <Typography source={'h6'}>Current Rate</Typography>
      </div>
    </div>
  )
}

export default GaugeChart
