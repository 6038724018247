import React, { useEffect, useState } from 'react'
import localStorageService from '../utils/localStorageService'
export const UserContext = React.createContext()
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(localStorageService.get('user'))
  useEffect(() => {
    setUser(localStorageService.get('user'))
  }, [])

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  )
}
