import * as Yup from 'yup'
import { number } from 'yup'
import uiText from '../resources/uiText'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .required(uiText.errors.email.required)
    .email(uiText.errors.email.invalid),
  password: Yup.string()
    .required(uiText.errors.password.required)
    .min(5, uiText.errors.password.invalid),
})

const activateSchema = Yup.object().shape({
  username: Yup.string()
    .required(uiText.errors.email.required)
    .email(uiText.errors.email.invalid),
  password: Yup.string()
    .required(uiText.errors.password.required)
    .min(5, uiText.errors.password.invalid),
  passwordConfirm: Yup.mixed().test(
    'match',
    'Passwords do not match',
    function () {
      return this.parent.password === this.parent.passwordConfirm
    }
  ),
  terms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
})

const resetSchema = Yup.object().shape({
  email: Yup.string()
    .required(uiText.errors.email.required)
    .email(uiText.errors.email.invalid),
})
const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required(uiText.errors.password.required)
    .min(5, uiText.errors.password.invalid),
  passwordConfirm: Yup.mixed().test(
    'match',
    'Passwords do not match',
    function () {
      return this.parent.password === this.parent.passwordConfirm
    }
  ),
})
const editClientSchema = Yup.object().shape({
  name: Yup.string()
    .required(uiText.errors.projectName.required)
    .min(2, uiText.errors.projectName.invalid),
  // projectNumber: Yup.number()
  //   .typeError('Project Number must be a number')
  //   .required(uiText.errors.projectNumber.required)
  //   .min(1, uiText.errors.projectNumber.invalid),
  projectLocation: Yup.string()
    .required(uiText.errors.projectLocation.required)
    .min(2, uiText.errors.projectLocation.invalid),
  projectStartDate: Yup.date().required(uiText.errors.projectStartDate),
  projectEndDate: Yup.date()
    .required(uiText.errors.projectStartDate)
    .min(Yup.ref('projectStartDate'), `End date can't be before start date`)
    .required(uiText.errors.projectEndDate),
  // projectPhase: Yup.string()
  //   .required(uiText.errors.projectPhase.required)
  //   .min(1, uiText.errors.projectPhase.invalid),
  LTIRMin: number().moreThan(0).min(0, uiText.errors.LTIRMin.invalid),
  LTIRMax: number().moreThan(0).min(0, uiText.errors.LTIRMax.invalid),
  TRIRMin: number().moreThan(0).min(0, uiText.errors.TRIRMin.invalid),
  TRIRMax: number().moreThan(0).min(0, uiText.errors.TRIRMax.invalid),
  DARTMin: number().moreThan(0).min(0, uiText.errors.DARTMin.invalid),
  DARTMax: number().moreThan(0).min(0, uiText.errors.DARTMax.invalid),
})

const addUserSchema = Yup.object().shape({
  firstname: Yup.string()
    .required(uiText.errors.firstName.required)
    .min(2, uiText.errors.firstName.invalid),
  lastname: Yup.string()
    .required(uiText.errors.lastName.required)
    .min(2, uiText.errors.lastName.invalid),
  emailAddress: Yup.string()
    .required(uiText.errors.email.required)
    .email(uiText.errors.email.invalid),
  // contractor: Yup.string().required(uiText.errors.postcode.required),
})
const kpiSchema = Yup.object().shape({
  type: Yup.string().min(1),
  from: Yup.date(),
  to: Yup.date().min(Yup.ref('from'), `End date can't be before start date`),
})

const dataReportSchema = Yup.object().shape({
  weeklyWorkedHours: Yup.number().moreThan(-1).typeError('Must be a number'),
  numOfWorkers1: Yup.number().moreThan(-1).typeError('Must be a number'),
  numOfWorkers2: Yup.number().moreThan(-1).typeError('Must be a number'),
  numOfWorkers3: Yup.number().moreThan(-1).typeError('Must be a number'),
  numOfWorkers4: Yup.number().moreThan(-1).typeError('Must be a number'),
  numOfWorkers5: Yup.number().moreThan(-1).typeError('Must be a number'),
  numOfWorkers6: Yup.number().moreThan(-1).typeError('Must be a number'),
  numOfWorkers7: Yup.number().moreThan(-1).typeError('Must be a number'),
  numOfPropertyDamagedEvents: Yup.number()
    .moreThan(-1)
    .typeError('Must be a number'),
  numNearMisses: Yup.number().moreThan(-1).typeError('Must be a number'),
  numFirstAidInjuries: Yup.number().moreThan(-1).typeError('Must be a number'),
  medicalTreatmentInjuries: Yup.number()
    .moreThan(-1)
    .typeError('Must be a number'),
  numRestrictedWorkCase: Yup.number()
    .moreThan(-1)
    .typeError('Must be a number'),
  numLostDays: Yup.number().moreThan(-1).typeError('Must be a number'),
  numLostTimeInjuries: Yup.number().moreThan(-1).typeError('Must be a number'),
  numDeaths: Yup.number().moreThan(-1).typeError('Must be a number'),
  numRIDDORSpecifiedInjuries: Yup.number()
    .moreThan(-1)
    .typeError('Must be a number'),
  numRIDDORMajorInjuries: Yup.number()
    .moreThan(-1)
    .typeError('Must be a number'),
  numRIDDORDangerousOccurrences: Yup.number()
    .moreThan(-1)
    .typeError('Must be a number'),
  numRIDDOROccupationalIllnesses: Yup.number()
    .moreThan(-1)
    .typeError('Must be a number'),
  numRIDDOR7DayInjuries: Yup.number()
    .moreThan(-1)
    .typeError('Must be a number'),
  numOSHAJobTransferCases: Yup.number()
    .moreThan(-1)
    .typeError('Must be a number'),
  numOSHAJobTransferDays: Yup.number()
    .moreThan(-1)
    .typeError('Must be a number'),
  numOSHARecordableInjuries: Yup.number()
    .moreThan(-1)
    .typeError('Must be a number'),
  numLossConciounessCases: Yup.number()
    .moreThan(-1)
    .typeError('Must be a number'),
  numTier1PSECount: Yup.number().moreThan(-1).typeError('Must be a number'),
  numTier2PSECount: Yup.number().moreThan(-1).typeError('Must be a number'),
  numTier3PSECount: Yup.number().moreThan(-1).typeError('Must be a number'),
})

export {
  editClientSchema,
  resetSchema,
  activateSchema,
  loginSchema,
  addUserSchema,
  kpiSchema,
  passwordSchema,
  dataReportSchema,
}
