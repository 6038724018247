import React from 'react'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  layout: {
    padding: 30,
  },
}
const PieChart = ({ data, labels, colors }) => {
  const set = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: colors,
        hoverOffset: 50,
        borderWidth: 1,
        rotation: 10,
      },
    ],
  }
  return (
    <div style={{ width: 250 }}>
      <Pie data={set} options={options} />
    </div>
  )
}

export default PieChart
