import React from 'react'
import TextField from './TextField'
import uuid from 'react-uuid'
import IconButton from './IconButton'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Typography from './Typography'
import color from '../styles/color'
import Card from '@mui/material/Card'

const DynamicList = (props) => {
  const {
    data,
    setData,
    type,
    label,
    heading,
    setRemoved,
    addText,
    emailValidate,
  } = props
  const handleAddBox = () => {
    setData(type, uuid(), '', '')
  }
  const handleRemoveBox = (id, type) => {
    const editedBoxArray = data[type].filter((item) => item.id !== id)
    setRemoved(type, editedBoxArray)
  }
  return (
    <div>
      <Typography source="subtitle2">{heading}</Typography>
      {data?.[type]?.map((box, index) => {
        return (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <TextField
                {...props}
                label={label}
                value={box.value}
                onChange={(e) => {
                  const emailRegex =
                    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?!\S)/
                  if (emailValidate) {
                    if (!emailRegex.test(e.target.value)) {
                      setData(
                        type,
                        box.id,
                        e.target.value,
                        'Email format is invalid'
                      )
                    } else {
                      setData(type, box.id, e.target.value, '')
                    }
                  } else {
                    setData(type, box.id, e.target.value, '')
                  }
                }}
                source={'bordered'}
              />
              <Card
                className="error"
                sx={
                  box.error
                    ? {
                        background: '#ff4438',
                        color: 'white',
                        marginBottom: '15px',
                        padding: 1,
                        borderRadius: '10',
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          marginTop: '-15px',
                          marginLeft: '6  px',
                          width: 0,
                          height: 0,
                          borderBottom: '9px solid #ff4438',
                          borderLeft: '15px solid transparent',
                          borderRight: '15px  solid transparent',
                        },
                      }
                    : {}
                }
              >
                {box.error ? <div>{box.error}</div> : <></>}
              </Card>
            </div>
            <IconButton
              style={{ marginTop: 40, marginLeft: 10 }}
              source="remove"
              onClick={() => handleRemoveBox(box.id, type)}
            >
              <RemoveIcon />
            </IconButton>
          </div>
        )
      })}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 5,
        }}
      >
        <IconButton onClick={() => handleAddBox()} source={'add'}>
          <AddIcon />
        </IconButton>
        <Typography
          {...props}
          style={{ color: color.primary, fontSize: 14, marginLeft: 5 }}
        >
          {addText}
        </Typography>
      </div>
    </div>
  )
}
export default DynamicList
