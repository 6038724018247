import React, { useState, useMemo } from 'react'
import { ThemeProvider } from './ui-library/components/ThemeContext'
import StackContext from './context/StackContext'
import AppChild from './AppChild'
import { UserProvider } from './context/UserContext'
import { AlertProvider } from './context/AlertContext'
import { OrganisationProvider } from './context/OrganisationContext'
import './assets/fonts/Noto_Sans/NotoSans-Black.ttf'
import './App.css'
import background from './assets/desktop-background.png'
import AltBackground from './ui-library/components/AltBackground'
import { CookiesProvider } from 'react-cookie'

function App() {
  const [stack, setStack] = useState([])
  const stackProvider = useMemo(() => ({ stack, setStack }), [stack, setStack])

  return (
    <AltBackground backgroundType="backgroundImage" value={background}>
      <CookiesProvider>
        <ThemeProvider>
          <StackContext.Provider value={stackProvider}>
            <UserProvider>
              <AlertProvider>
                <OrganisationProvider>
                  <AppChild />
                </OrganisationProvider>
              </AlertProvider>
            </UserProvider>
          </StackContext.Provider>
        </ThemeProvider>
      </CookiesProvider>
    </AltBackground>
  )
}

export default App
