const style = {
  auth: {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    },
    layout: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  sideBar: {
    padding: '10px 0',
    minHeight: '650px',
  },
  activeSideBar: {
    width: '350px',
    borderRadius: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '25px',
      height: '25px',
      right: '-12px',
      transform: 'rotate(45deg)',
    },
  },
  appBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '40px',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: 'black',
    color: '#b7b8bd',
    svg: {
      fontSize: '40px',
    },
  },
  error: {
    background: '#ff4438',
    color: 'white',
    marginBottom: '15px',
    width: '95%',
    padding: 1,
    borderRadius: '10',
    '&::before': {
      content: '""',
      position: 'absolute',
      marginTop: '-15px',
      marginLeft: '6  px',
      width: 0,
      height: 0,
      borderBottom: '9px solid #ff4438',
      borderLeft: '15px solid transparent',
      borderRight: '15px  solid transparent',
    },
  },
  dashboard: {
    maxWidth: '90%',
  },
  timeoutStyle: {
    borderRadius: '10px',
  },
}

export default style
