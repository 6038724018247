import React, { useContext, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '../../../ui-library/components/Typography'
import { useFormik } from 'formik'
import TextField from '../../../ui-library/components/TextField'
import { ThemeContext } from '../../../ui-library/components/ThemeContext'
import TabHeader from '../../shared/TabHeader'
import { getFormData } from '../../../resources/formData'
import LoadingButton from '../../../ui-library/components/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import { BiReset } from 'react-icons/bi'
import {
  getWeeklyDataReportForm,
  postWeeklyDataReport,
} from '@northfoxgroup/frontend-api-lib'
import { AlertContext } from '../../../context/AlertContext'
import { dataReportSchema } from '../../../config/validation'
import Button from '../../../ui-library/components/Button'

function HSEForm(props) {
  const { tab } = props
  const { theme } = useContext(ThemeContext)
  const [formData, setFormData] = useState([])
  const initial = {
    weeklyWorkedHours: 0,
    numOfWorkers0: 0,
    numOfWorkers2: 0,
    numOfWorkers3: 0,
    numOfWorkers4: 0,
    numOfWorkers5: 0,
    numOfWorkers6: 0,
    numOfWorkers7: 0,
    numOfPropertyDamagedEvents: 0,
    numNearMisses: 0,
    numFirstAidInjuries: 0,
    medicalTreatmentInjuries: 0,
    numRestrictedWorkCase: 0,
    numLostDays: 0,
    numLostTimeInjuries: 0,
    numDeaths: 0,
    numRIDDORSpecifiedInjuries: 0,
    numRIDDORMajorInjuries: 0,
    numRIDDORDangerousOccurrences: 0,
    numRIDDOROccupationalIllnesses: 0,
    numRIDDOR7DayInjuries: 0,
    numOSHAJobTransferCases: 0,
    numOSHAJobTransferDays: 0,
    numOSHARecordableInjuries: 0,
    numLossConciounessCases: 0,
    numTier0PSECount: 0,
    numTier2PSECount: 0,
    numTier3PSECount: 0,
  }
  const { values, handleSubmit, setFieldValue, handleBlur, errors, touched } =
    useFormik({
      initialValues: initial,
      validationSchema: dataReportSchema,
      onSubmit: () => handleSubmitForm(),
    })
  const [loading, setLoading] = useState(false)
  const { setAlert } = useContext(AlertContext)
  const handleSubmitForm = async () => {
    setLoading(true)
    const res = await postWeeklyDataReport(values)
    setLoading(false)
    if (res.name === 'AxiosError') {
      return setAlert({
        open: true,
        severity: 'error',
        message: 'Error submitting form',
      })
    }
    setAlert({
      open: true,
      severity: 'success',
      message: 'Form submitted successfully',
    })
  }
  useEffect(() => {
    const getForm = async () => {
      const res = await getWeeklyDataReportForm()
      setFormData(res?.data?.data)
    }
    getForm()
  }, [])

  const boldHeadings = [
    'Number of events resulting in property damage ?',
    'Number of First Aid Injuries ?',
    'Number of Fatalities ?',
    'Number of OSHA Job Transfer Cases',
    'Number of OSHA Job Transfer Days',
    'Number of OSHA recordable injuries',
    'Number of losses of OSHA consciousness cases',
  ]

  return (
    <>
      <TabHeader
        tabTitle={'Weekly Data Report'}
        getColor={getFormData(tab, 'color')}
      >
        <Paper style={theme.dashboard.container}>
          <form onSubmit={handleSubmit}>
            {formData?.length > 0 &&
              formData?.map((item) => {
                return (
                  <>
                    {item?.sectionName && (
                      <Typography source={'h6'}>{item?.sectionName}</Typography>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                        // flexWrap: 'wrap',
                      }}
                    >
                      {item?.questions?.map((data, index) => {
                        const isBold = boldHeadings.includes(data?.questionText)
                          ? { title: { fontWeight: 'bolder' } }
                          : {}
                        return (
                          <TextField
                            key={index}
                            type="text"
                            name={data.returnAPIName}
                            title={data.questionText}
                            value={values.returnAPIName}
                            onChange={(val) => {
                              setFieldValue(
                                data.returnAPIName,
                                parseInt(val.target.value)
                              )
                            }}
                            onBlur={handleBlur}
                            errorType={data.returnAPIName}
                            errors={errors}
                            touched={touched}
                            source={'bordered'}
                            style={isBold}
                            // style={
                            //   item?.questions?.length > 1
                            //     ? {
                            //         width: `${200 / item?.questions?.length}%`,
                            //       }
                            //     : {}
                            // }
                          />
                        )
                      })}
                    </div>
                  </>
                )
              })}

            <LoadingButton
              type="submit"
              source={'secondary'}
              style={{
                width: 140,
                height: 40,
                justifyContent: 'space-between',
                marginTop: 20,
              }}
              loading={loading}
              endIcon={<SaveIcon />}
            >
              Save
            </LoadingButton>
            <Button
              style={{
                width: 140,
                height: 40,
                justifyContent: 'space-between',
                marginTop: 20,
                marginLeft: 20,
              }}
              type="reset"
              source={'error'}
              endIcon={<BiReset />}
            >
              Reset
            </Button>
          </form>
        </Paper>
      </TabHeader>
    </>
  )
}

export default HSEForm
