import MuiLink from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import React, { useContext } from 'react'
import { ThemeContext } from './ThemeContext'
import Typography from './Typography'

const CssLink = styled(MuiLink)(({ theme, style }) => {
  return { ...theme?.link, ...style }
})

const Link = (props) => {
  const { children } = props
  const { theme } = useContext(ThemeContext)
  return (
    <>
      <CssLink {...props} theme={theme}>
        <Typography {...props}>{children}</Typography>
      </CssLink>
    </>
  )
}
export default Link
