import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from '../../screens/Dashboard'
import theme from '../../style/theme'
import Login from '../../screens/auth/Login'
import Register from '../../screens/auth/Register'
import Reset from '../../screens/auth/Reset'
import NotFound from '../../screens/NotFound'
import Password from '../../screens/auth/Password'
import KitchenSink from '../../ui-library/KitchenSink'

function AuthRouter() {
  return (
    <Routes>
      <Route path="/sink" element={<KitchenSink />} />

      <Route
        path="/dashboard"
        element={<Dashboard spacing={theme.spacing} />}
      />
      <Route path="/login" element={<Login spacing={theme.spacing} />} />
      <Route path="/register" element={<Register spacing={theme.spacing} />} />
      <Route path="/reset" element={<Reset spacing={theme.spacing} />} />
      <Route
        path="/reset-password"
        element={<Password spacing={theme.spacing} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AuthRouter
