import React, { useContext, useEffect } from 'react'
import Typography from '../ui-library/components/Typography'
import routeHandler from '../handlers/routeHandler'
import { UserContext } from '../context/UserContext'
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingAnim from '../ui-library/components/loadingAnim/LoadingAnim'

const NotFound = () => {
  const { user } = useContext(UserContext)
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const handleRoutes = async () => {
      await routeHandler(navigate, user)
    }
    handleRoutes()
  }, [location])
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography source="h1">404 Not Found</Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          width: '25%',
        }}
      >
        <Typography source="breadcrumb">Redirecting</Typography>
      </div>
      <LoadingAnim />
    </div>
  )
}

export default NotFound
