const chartColors = {
  a: '#0D98E8',
  b: '#00E7FF',
  c: '#FF1896',
  d: '#FF753F',
  e: '#0BE8C1',
  f: '#CFD370',
  g: '#FF2E0E',
  h: '#FFB6C1',
  i: '#EE82EE',
  j: '#00FF7F',
  k: '#FFFF00',
  l: '#6495ED',
  m: '#4CAF50',
  n: '#FFC107',
  o: '#9C27B0',
  p: '#FF5722',
  q: '#03A9F4',
  r: '#FF9800',
  s: '#607D8B',
  t: '#8BC34A',
  u: '#CDDC39',
  v: '#F44336',
  w: '#FF8A80',
  x: '#BA68C8',
  y: '#FFD740',
  z: '#008080',
  A: '#FFCDD2',
  B: '#7986CB',
  C: '#80DEEA',
  D: '#FFF59D',
  E: '#F8BBD0',
  F: '#E0E0E0',
  G: '#C5CAE9',
  H: '#B2DFDB',
  I: '#FFAB91',
  J: '#BDBDBD',
  K: '#C8E6C9',
  L: '#FFE082',
  M: '#B0BEC5',
  N: '#E1BEE7',
  O: '#81D4FA',
  P: '#A5D6A7',
}

export default chartColors
