import {
  addAdminUser,
  addAppUser,
  addKPIUser,
  addHSEUser,
  assignContractor,
  deleteUser,
  getUsers,
  resetPassword,
  updateUser,
} from '@northfoxgroup/frontend-api-lib'
import uiText from '../../../resources/uiText'

const editAppUser = async (id, values, setAlert, setLoading, contractorId) => {
  setLoading(true)
  const data = {
    ...values,
    type: values.type.value,
  }
  const req = await updateUser(id, data)
  await assignContractor(contractorId, id)
  if (req.name === 'AxiosError') {
    setLoading(false)
    setAlert({
      open: true,
      severity: 'error',
      message: 'Error updating user',
      action: false,
    })
    return
  }
  setLoading(false)
  setAlert({
    open: true,
    severity: 'success',
    message: 'User updated',
    action: false,
  })
}

const addHSEUserHandler = async (values, contractor, setAlert, setLoading) => {
  setLoading(true)
  const addUserData = {
    username: values.emailAddress,
    email: values.emailAddress,
    firstname: values.firstname,
    lastname: values.lastname,
    type: values.type.value,
  }
  const req = await addHSEUser(addUserData, contractor)
  if (req.name === 'AxiosError') {
    setAlert({
      open: true,
      severity: 'error',
      message: 'Error adding user',
      action: false,
    })
    setLoading(false)
    return
  }
  setLoading(false)
  setAlert({
    open: true,
    severity: 'success',
    message: 'User added.',
    action: false,
  })
}

const addKPIUserHandler = async (values, contractor, setAlert, setLoading) => {
  setLoading(true)
  const addUserData = {
    username: values.emailAddress,
    email: values.emailAddress,
    firstname: values.firstname,
    lastname: values.lastname,
    type: values.type.value,
  }
  const req = await addKPIUser(addUserData, contractor)
  if (req.name === 'AxiosError') {
    setAlert({
      open: true,
      severity: 'error',
      message: 'Error adding user',
      action: false,
    })
    setLoading(false)
    return
  }
  setLoading(false)
  setAlert({
    open: true,
    severity: 'success',
    message: 'User added.',
    action: false,
  })
}

const addAdminUserHandler = async (
  values,
  contractor,
  setAlert,
  setLoading
) => {
  setLoading(true)
  const addUserData = {
    username: values.emailAddress,
    email: values.emailAddress,
    firstname: values.firstname,
    lastname: values.lastname,
    type: values.type.value,
  }
  const req = await addAdminUser(addUserData, contractor)
  if (req.name === 'AxiosError') {
    setAlert({
      open: true,
      severity: 'error',
      message: 'Error adding user',
      action: false,
    })
    setLoading(false)
    return
  }
  setLoading(false)
  setAlert({
    open: true,
    severity: 'success',
    message: 'User added.',
    action: false,
  })
}

const addAppUserHandler = async (
  values,
  setAlert,
  setLoading,
  contractorId
) => {
  setLoading(true)

  const addUserData = {
    username: values.emailAddress,
    email: values.emailAddress,
    firstname: values.firstname,
    lastname: values.lastname,
    type: values.type.value,
  }
  const req = await addAppUser(addUserData, contractorId)
  await assignContractor(contractorId, req.data.id)
  if (req.name === 'AxiosError') {
    setAlert({
      open: true,
      severity: 'error',
      message: 'Error adding user',
      action: false,
    })
    setLoading(false)
    return
  }
  setLoading(false)
  setAlert({
    open: true,
    severity: 'success',
    message: 'User added.',
    action: false,
  })
}

const getAppUsers = async (search = '', type, sort) => {
  let params = ''
  switch (type) {
    case 'ADMIN_ROLE':
      params =
        '{"$or": [{"email": {"$contL": "' +
        search +
        '"}}, {"firstname": {"$contL": "' +
        search +
        '"}},' +
        '{"lastname": {"$contL": "' +
        search +
        '"}}' +
        ']}'
      break
    case 'KPI_ROLE':
      params =
        '{"$or": [{"email": {"$contL": "' +
        search +
        '"}}, {"firstname": {"$contL": "' +
        search +
        '"}},' +
        '{"lastname": {"$contL": "' +
        search +
        '"}}' +
        '], "type": { "$in": ["APP_ROLE", "HSE_ROLE", "KPI_ROLE"]}}'
      break
  }
  return await getUsers(params, sort)
}

const deleteAppUser = async (id, setAlert) => {
  const req = await deleteUser(id)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: 'Error deleting user',
      action: false,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'User deleted.',
    action: false,
  })
}
const resetAppUser = async (email, setAlert) => {
  const req = await resetPassword(email)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: uiText.errors.reset,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'Password reset request sent',
  })
}
export {
  editAppUser,
  addKPIUserHandler,
  addHSEUserHandler,
  addAdminUserHandler,
  addAppUserHandler,
  getAppUsers,
  deleteAppUser,
  resetAppUser,
}
