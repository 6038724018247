import React, { useState } from 'react'

export const OrganisationContext = React.createContext()
export const OrganisationProvider = ({ children }) => {
  const [organisations, setOrganisations] = useState(``)
  const [currentOrg, setCurrentOrg] = useState('')
  return (
    <OrganisationContext.Provider
      value={{ organisations, setOrganisations, currentOrg, setCurrentOrg }}
    >
      {children}
    </OrganisationContext.Provider>
  )
}
