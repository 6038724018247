import React, { useContext, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthRouter from './navigation/routing/AuthRouter'
import { logout, refresh, setApiUrl } from '@northfoxgroup/frontend-api-lib'
import { UserContext } from './context/UserContext'
import Alerts from './components/shared/Alerts'
import { AlertContext } from './context/AlertContext'
import routeHandler from './handlers/routeHandler'
import { ThemeContext } from './ui-library/components/ThemeContext'
import theme from './ui-library/styles/theme'
import Grid from '@mui/material/Grid'
import { StyledEngineProvider } from '@mui/material/styles'
import LoadingAnim from './ui-library/components/loadingAnim/LoadingAnim'
import localStorageService from './utils/localStorageService'

function AppChild() {
  // const url = 'http://localhost:1337/'
  const url = 'https://oldnexusapi.ebiew.com/'
  const navigate = useNavigate()
  const { user, setUser } = useContext(UserContext)
  const { alert, setAlert } = useContext(AlertContext)
  const location = useLocation()
  const { setTheme } = useContext(ThemeContext)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const handleRoutes = async () => {
      setApiUrl(url)
      await refresh()
      window.addEventListener(
        'storage',
        async () => await routeHandler(navigate, user)
      )
      await refresh()
      setLoading(false)
      await routeHandler(navigate, user)
    }
    handleRoutes()
  }, [location])

  useEffect(() => {
    setTheme(theme)
    setApiUrl(url)
  }, [])
  setTimeout(async () => {
    const checkToken = await refresh()
    if (!checkToken) {
      localStorageService.clear()
      setUser(null)
      navigate('/login')
      await logout()
    }
  }, 240000)
  // stops requests running before API url is updated on refresh and load
  if (loading)
    return (
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingAnim />
      </div>
    )
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=0.86, maximum-scale=5.0, minimum-scale=0.86"
          />
        </Helmet>
      </HelmetProvider>
      <StyledEngineProvider injectFirst>
        <Grid container>
          <AuthRouter />
        </Grid>
        <Alerts alert={alert} setAlert={setAlert} />
      </StyledEngineProvider>
    </>
  )
}

export default AppChild
