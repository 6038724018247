import React from 'react'
import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from 'chart.js'
import { Radar } from 'react-chartjs-2'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
}
const RadarChart = ({ data, labels, colors, width = 200 }) => {
  const set = {
    labels,
    datasets: data.map((set) => {
      return {
        label: set.label,
        data: set.data,
        borderColor: colors,
        backgroundColor: colors,
      }
    }),
  }
  return (
    <div>
      <Radar data={set} options={options} style={{ width }} />
    </div>
  )
}

export default RadarChart
