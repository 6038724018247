import React from 'react'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import Link from './Link'
import Typography from '@mui/material/Typography'
import uuid from 'react-uuid'

const BreadCrumbs = (props) => {
  const { list } = props

  return (
    <MuiBreadcrumbs separator="›" aria-label="breadcrumb">
      {list?.map((item) => {
        return (
          <Link key={uuid()} underline="hover" color="inherit" href="/">
            <Typography source={'breadcrumb'}>{item}</Typography>
          </Link>
        )
      })}
    </MuiBreadcrumbs>
  )
}

export default BreadCrumbs
