import React, { useContext, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '../../../ui-library/components/Typography'
import { useFormik } from 'formik'
import { addUserSchema } from '../../../config/validation'
import ErrorCard from '../../shared/ErrorCard'
import {
  addAdminUserHandler,
  addAppUserHandler,
  addHSEUserHandler,
  addKPIUserHandler,
  deleteAppUser,
  editAppUser,
  resetAppUser,
} from '../../../handlers/requests/users/appUsersHandler'
import ConfirmationModal from '../../dialogs/ConfirmationModal'
import { AlertContext } from '../../../context/AlertContext'
import TextField from '../../../ui-library/components/TextField'
import Dropdown from '../../../ui-library/components/Dropdown'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Button from '../../../ui-library/components/Button'
import Link from '../../../ui-library/components/Link'
import LockIcon from '@mui/icons-material/Lock'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import { ThemeContext } from '../../../ui-library/components/ThemeContext'
import LoadingButton from '../../../ui-library/components/LoadingButton'
import { getOrgContractors } from '@northfoxgroup/frontend-api-lib'
import { OrganisationContext } from '../../../context/OrganisationContext'
import { UserContext } from '../../../context/UserContext'

function EditAppUsers(props) {
  const { setEdit, isEdit, selectedUser } = props
  const [loading, setLoading] = useState(false)
  const { setAlert } = useContext(AlertContext)
  const { currentOrg } = useContext(OrganisationContext)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmReset, setConfirmReset] = useState(false)
  const [selectedContractor, setSelectedContractor] = useState()
  const [userTypeDropDown, setUserTypeDropDown] = useState([])
  const { theme } = useContext(ThemeContext)
  const { user } = useContext(UserContext)
  const initialEdit = {
    firstname: selectedUser?.firstname || '',
    lastname: selectedUser?.lastname || '',
    emailAddress: selectedUser?.email || '',
    type: selectedUser?.type || '',
  }
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: isEdit
      ? {
          firstname: '',
          lastname: '',
          emailAddress: '',
          type: '',
          contractors: [],
        }
      : initialEdit,
    validationSchema: addUserSchema,
    onSubmit: () => handleIsEdit(),
  })
  const handleIsEdit = async () => {
    if (!isEdit) {
      await editAppUser(
        selectedUser?.id,
        values,
        setAlert,
        setLoading,
        selectedContractor?.id
      )
    } else {
      switch (values.type.value) {
        case 'APP_ROLE':
          await addAppUserHandler(
            values,
            setAlert,
            setLoading,
            selectedContractor?.id
          )
          break
        case 'KPI_ROLE':
          await addKPIUserHandler(
            values,
            selectedContractor?.id,
            setAlert,
            setLoading
          )
          break
        case 'ADMIN_ROLE':
          await addAdminUserHandler(
            values,
            selectedContractor?.id,
            setAlert,
            setLoading
          )
          break
        case 'HSE_ROLE':
          await addHSEUserHandler(
            values,
            selectedContractor?.id,
            setAlert,
            setLoading
          )
          break
      }
    }
    setEdit(false)
  }
  useEffect(() => {
    const getContractors = async () => {
      const { data } = await getOrgContractors()
      await setFieldValue('contractors', data)
      if (!isEdit) {
        const contractorAssigned = selectedUser?.contractors?.find(
          (contractor) => contractor.organisation === currentOrg.id
        )
        setSelectedContractor(contractorAssigned)
      }
    }
    getContractors()
  }, [currentOrg])

  useEffect(() => {
    switch (selectedUser?.type) {
      case 'APP_ROLE':
        setFieldValue('type', { value: 'APP_ROLE', label: 'App User' })
        break
      case 'KPI_ROLE':
        setFieldValue('type', { value: 'KPI_ROLE', label: 'KPI' })
        break
      case 'ADMIN_ROLE':
        setFieldValue('type', { value: 'ADMIN_ROLE', label: 'Admin' })
        break
      case 'HSE_ROLE':
        setFieldValue('type', { value: 'HSE_ROLE', label: 'HSE Manager' })
        break
    }

    switch (user.type) {
      case 'KPI_ROLE':
        setUserTypeDropDown([
          { key: 1, label: 'App Only', value: 'APP_ROLE' },
          { key: 2, label: 'KPI', value: 'KPI_ROLE' },
          { key: 3, label: 'HSE Manager', value: 'HSE_ROLE' },
        ])
        break
      case 'ADMIN_ROLE':
        setUserTypeDropDown([
          { key: 1, label: 'App Only', value: 'APP_ROLE' },
          { key: 2, label: 'KPI', value: 'KPI_ROLE' },
          { key: 3, label: 'Admin', value: 'ADMIN_ROLE' },
          { key: 4, label: 'HSE Manager', value: 'HSE_ROLE' },
        ])
        break
      default:
        setUserTypeDropDown([])
        break
    }
  }, [])
  return (
    <>
      <Paper style={theme.dashboard.container}>
        <Link
          type="submit"
          onClick={() => {
            setEdit(false)
          }}
        >
          {`< Back`}
        </Link>
        <Typography source="h3">User Details</Typography>

        {isEdit ? (
          <></>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: 340,
              marginTop: 20,
              marginBottom: 5,
            }}
          >
            <Button
              source={'resetPasswordButton'}
              style={{
                width: 180,
                height: 40,
                justifyContent: 'space-between',
              }}
              onClick={() => {
                setConfirmReset(true)
              }}
              endIcon={<LockIcon />}
            >
              Reset Password
            </Button>
            <Button
              source={'error'}
              style={{
                width: 150,
                height: 40,
                justifyContent: 'space-between',
              }}
              onClick={() => {
                setConfirmDelete(true)
              }}
              endIcon={<DeleteIcon />}
            >
              Delete User
            </Button>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <TextField
              type="firstname"
              name="firstname"
              title="First Name*"
              placeholder="First Name*"
              value={values.firstname}
              onChange={handleChange}
              onBlur={handleBlur}
              errorType={'firstname'}
              errors={errors}
              touched={touched}
              source={'bordered'}
            />
            <TextField
              type="lastname"
              name="lastname"
              title="Last Name*"
              placeholder="Last Name*"
              value={values.lastname}
              onChange={handleChange}
              onBlur={handleBlur}
              errorType={'lastname'}
              errors={errors}
              touched={touched}
              source={'bordered'}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <TextField
              type="emailAddress"
              name="emailAddress"
              title="Email Address*"
              placeholder="Email Address*"
              value={values.emailAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              errorType={`emailAddress`}
              errors={errors}
              touched={touched}
              source={'bordered'}
            />
            <Dropdown
              type="type"
              id={'type'}
              name={'type'}
              title={'Type*'}
              setSelected={(value) => setFieldValue('type', value)}
              onBlur={handleBlur}
              selected={{ label: values.type.label, value: values.type.value }}
              items={userTypeDropDown}
              inputLabel="Type"
              Icon={ArrowDropDownIcon}
              errorType={'type'}
              errors={errors}
              touched={touched}
            />
          </div>
          <div>
            <Dropdown
              type="contractor"
              id={'contractor'}
              name={'contractor'}
              title={'Contractor*'}
              setSelected={(value) => {
                setSelectedContractor(value.value)
              }}
              onBlur={handleBlur}
              selected={{
                label: selectedContractor?.name,
                value: selectedContractor,
              }}
              items={values?.contractors?.map((contractor) => ({
                label: contractor?.name,
                value: contractor,
              }))}
              inputLabel="Contractor"
            />
            <ErrorCard type={'contractor'} errors={errors} touched={touched} />
          </div>
          <LoadingButton
            type="submit"
            source={'secondary'}
            style={{
              width: 120,
              height: 40,
              justifyContent: 'space-between',
              marginTop: 20,
            }}
            loading={loading}
            endIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </form>
        <div style={{ paddingTop: 20, paddingLeft: 5 }}>
          <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 5 }}>
            <Typography source={'h5'}> Admin - </Typography>
            <Typography
              source={'body'}
              style={{ width: '75%', paddingLeft: 10 }}
            >
              This user type will have access to the KPI data, the mobile
              application, and will be able to add, remove, and alter app user
              data in addition to changing individual client information.
            </Typography>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', paddingTop: 15 }}
          >
            <Typography source={'h5'}>KPI - </Typography>
            <Typography
              source={'body'}
              style={{ width: '75%', paddingLeft: 32 }}
            >
              This user type will have access to the mobile application as well
              as the statistics displayed under the KPI tab.
            </Typography>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', paddingTop: 15 }}
          >
            <Typography source={'h5'}>App - </Typography>
            <Typography
              source={'body'}
              style={{ width: '75%', paddingLeft: 28 }}
            >
              This user type will only have access to mobile applications.
            </Typography>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', paddingTop: 15 }}
          >
            <Typography source={'h5'}>HSE - </Typography>
            <Typography
              source={'body'}
              style={{ width: '75%', paddingLeft: 28 }}
            >
              This user type will have access to the Weekly Data report on the
              portal
            </Typography>
          </div>
        </div>
        <ConfirmationModal
          onAccept={async () => {
            await deleteAppUser(selectedUser?.id, setAlert)
            setEdit(false)
          }}
          isOpen={confirmDelete}
          onRequestClose={() => setConfirmDelete(false)}
          type={'Delete'}
          text={'Are you sure you wish to delete this user?'}
        />
        <ConfirmationModal
          onAccept={async () => {
            await resetAppUser(selectedUser?.email, setAlert)
            setConfirmReset(false)
          }}
          isOpen={confirmReset}
          onRequestClose={() => setConfirmReset(false)}
          type={'Reset'}
          text={'Are you sure you wish to reset this users password?'}
        />
      </Paper>
    </>
  )
}

export default EditAppUsers
