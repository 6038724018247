import React, { useContext, useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import {
  deleteClient,
  getClients,
} from '../../../handlers/requests/clients/clientsHandler'
import { OrganisationContext } from '../../../context/OrganisationContext'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../ui-library/components/DataTable'
import Button from '../../../ui-library/components/Button'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { ThemeContext } from '../../../ui-library/components/ThemeContext'
import ConfirmationModal from '../../dialogs/ConfirmationModal'
import { AlertContext } from '../../../context/AlertContext'
import useDebounce from '../../../hooks/useDebounce'
import Typography from '../../../ui-library/components/Typography'
import color from '../../../ui-library/styles/color'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import IconButton from '../../../ui-library/components/IconButton'
import { FaEdit } from 'react-icons/fa'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import LoadingAnim from '../../../ui-library/components/loadingAnim/LoadingAnim'
import uuid from 'react-uuid'

function ClientList(props) {
  const { setEdit, setIsEdit, setSelectedClient, isEdit, search } = props
  const [clientData, setClientData] = useState([])
  const [loading, setLoading] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [removeId, setRemoveId] = useState()
  const { setAlert } = useContext(AlertContext)
  const debouncedSearch = useDebounce(search, 1000)
  const { currentOrg } = useContext(OrganisationContext)
  const { theme } = useContext(ThemeContext)
  const [sortOrder, setSortOrder] = useState('name,ASC')

  useEffect(() => {
    const populateList = async () => {
      setLoading(true)
      if (debouncedSearch) {
        const clients = await getClients(search, sortOrder)
        setClientData(clients?.data)
        setLoading(false)
      } else {
        const clients = await getClients(search, sortOrder)
        setClientData(clients?.data)

        setLoading(false)
      }
    }
    populateList()
  }, [isEdit, currentOrg, confirmDelete, search, sortOrder])

  const add = () => {
    setEdit(true)
    setIsEdit(false)
  }
  const edit = (client) => {
    setEdit(true)
    setIsEdit(true)
    setSelectedClient(client)
  }
  return (
    <>
      <Paper style={theme.dashboard.container}>
        <Button
          source={'secondary'}
          type="submit"
          onClick={add}
          style={{ width: 150, height: 40, justifyContent: 'space-between' }}
          endIcon={<AddCircleIcon style={{ width: 25, height: 25 }} />}
        >
          Add Client
        </Button>
        <>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <>
              <TableContainer {...props} theme={theme}>
                <Table {...props} theme={theme}>
                  <TableHead {...props} theme={theme}>
                    <TableCell {...props} theme={theme}>
                      <Typography
                        style={{
                          color: color.primary,
                          display: 'inline-block',
                          'vertical-align': 'middle',
                          'user-select': 'none',
                        }}
                      >
                        Name
                      </Typography>
                      <div
                        style={{
                          display: 'inline-block',
                          'vertical-align': 'middle',
                          marginTop: 7,
                        }}
                        onClick={() => {
                          if (sortOrder === 'name,DESC')
                            setSortOrder('name,ASC')
                          else {
                            setSortOrder('name,DESC')
                          }
                        }}
                      >
                        {sortOrder === 'name,ASC' ? (
                          <BiChevronDown
                            style={{
                              width: 25,
                              height: 25,
                              color: color.primary,
                            }}
                          />
                        ) : (
                          <BiChevronUp
                            style={{
                              width: 25,
                              height: 25,
                              color: color.primary,
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell {...props} theme={theme} align={'right'}>
                      <Typography
                        style={{
                          color: color.primary,
                          display: 'inline-block',
                          'vertical-align': 'middle',
                          'user-select': 'none',
                          float: 'right',
                        }}
                      >
                        Action
                      </Typography>
                    </TableCell>
                  </TableHead>
                  <TableBody {...props} theme={theme}>
                    {clientData?.map((client) => {
                      const name = client.name !== null ? client.name : ''
                      return (
                        <TableRow key={uuid()} {...props} theme={theme}>
                          <TableCell {...props} theme={theme}>
                            <Typography>{`${name}`}</Typography>
                          </TableCell>
                          <TableCell theme={theme} {...props}>
                            <IconButton
                              style={{
                                backgroundColor: 'white',
                                '&:hover': { opacity: 0.5 },
                                float: 'right',
                              }}
                            >
                              <DeleteForeverIcon
                                style={{
                                  color: color.error,
                                  fontSize: 27,
                                }}
                                onClick={() => {
                                  setRemoveId(client.id)
                                  setConfirmDelete(true)
                                }}
                              />
                            </IconButton>
                            <IconButton
                              style={{
                                backgroundColor: 'white',
                                '&:hover': { opacity: 0.5 },
                                float: 'right',
                              }}
                            >
                              <FaEdit
                                style={{
                                  color: color.primary,
                                }}
                                onClick={() => {
                                  edit(client)
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '50vh',
                  }}
                >
                  <LoadingAnim />
                </div>
              ) : (
                <></>
              )}
            </>
          </Stack>
        </>
        <ConfirmationModal
          onAccept={() => {
            deleteClient(removeId, setAlert)
            setTimeout(() => {
              setConfirmDelete(false)
            }, [1000])
          }}
          isOpen={confirmDelete}
          onRequestClose={() => setConfirmDelete(false)}
          type={'Delete'}
          text={'Are you sure you wish to delete this client?'}
        />
      </Paper>
    </>
  )
}

export default ClientList
