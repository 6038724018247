import React, { useContext, useState } from 'react'
import MuiTableContainer from '@mui/material/TableContainer'
import MuiTable from '@mui/material/Table'
import MuiTableHead from '@mui/material/TableHead'
import MuiTableRow from '@mui/material/TableRow'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableBody from '@mui/material/TableBody'
import styled from '@emotion/styled'
import { ThemeContext } from '../../../ui-library/components/ThemeContext'

import Typography from '../../../ui-library/components/Typography'
import moment from 'moment/moment'
import uuid from 'react-uuid'
import color from '../../../ui-library/styles/color'
import GenericModal from '../../dialogs/GenericModal'

const Table = styled(MuiTable)((props) => {
  const { style } = props
  return { ...style }
})
const TableContainer = styled(MuiTableContainer)((props) => {
  const { style } = props
  return {
    ...style,
  }
})
const TableHead = styled(MuiTableHead)((props) => {
  const { style } = props
  return {
    ...style,
  }
})
const TableRow = styled(MuiTableRow)((props) => {
  const { style } = props
  return { ...style }
})
const TableCell = styled(MuiTableCell)((props) => {
  const { style } = props
  return { ...style, borderBottom: '1px solid #000000' }
})
const TableBody = styled(MuiTableBody)((props) => {
  const { style } = props
  return {
    ...style,
  }
})

const ObservationsTable = (props) => {
  const { headers, observations } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const { theme } = useContext(ThemeContext)
  return (
    <>
      <TableContainer {...props} theme={theme}>
        <Table {...props} theme={theme}>
          <TableHead {...props} theme={theme}>
            {headers?.map((header, index) => {
              return (
                <TableCell {...props} key={index} theme={theme}>
                  <Typography
                    style={
                      header === 'Description'
                        ? { float: 'right', color: color.primary }
                        : { color: color.primary }
                    }
                  >
                    {header}
                  </Typography>
                </TableCell>
              )
            })}
          </TableHead>
          <TableBody {...props} theme={theme}>
            {observations?.map((obs) => (
              <TableRow key={uuid()} {...props} theme={theme}>
                <TableCell {...props} theme={theme}>
                  <Typography>
                    {moment(obs?.createdAt).format('DD/MM/YYYY')}
                  </Typography>
                </TableCell>
                <TableCell {...props} theme={theme}>
                  <Typography>{obs?.contractor?.name}</Typography>
                </TableCell>
                <TableCell {...props} theme={theme}>
                  <Typography>{obs?.category}</Typography>
                </TableCell>
                <TableCell {...props} theme={theme}>
                  <Typography>{obs?.riskLevel}</Typography>
                </TableCell>
                <TableCell {...props} theme={theme}>
                  {obs?.photoEvidence?.map((photo) => {
                    return (
                      <div
                        key={uuid()}
                        onClick={() => {
                          setSelectedImage(photo?.fileUrl)
                          setModalOpen(true)
                        }}
                      >
                        <img
                          src={photo.fileUrl}
                          alt="photo"
                          style={{ width: 40, height: 40 }}
                        />
                      </div>
                    )
                  })}
                </TableCell>
                <TableCell {...props} theme={theme}>
                  <Typography style={{ float: 'right' }}>
                    {obs?.observation}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {observations?.length === 0 && (
        <div
          style={{
            height: 200,
            borderRadius: 10,
            backgroundColor: '#e6e7e8',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            marginTop: 20,
          }}
        >
          <Typography style={{ color: 'white' }} source={'h3'}>
            No Data To Display
          </Typography>
        </div>
      )}
      <GenericModal
        isOpen={modalOpen}
        onRequestClose={() => {
          setModalOpen(false)
        }}
        photo={selectedImage}
      />
    </>
  )
}

export default ObservationsTable
