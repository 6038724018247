import React, { useState } from 'react'
import color from '../styles/color'
import background from '../../assets/desktop-background.png'

const defaultTheme = {
  MuiDatePicker: {
    // backgroundColor: lightBlue.A200,
    // color: "white",
    root: {
      backgroundColor: 'red',
    },
  },

  logo: {
    default: {},
    square: {},
  },
  background: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '100%',
        },
      },
    },
  },
  input: {},
  password: {
    input: {},
    strengthMeter: {},
  },
  checkBox: {
    color: color.primary,
    '&.Mui-checked': {
      color: color.primary,
    },
  },
  radioButton: {},
  alert: {},
  textField: {
    '& .MuiOutlinedInput-root': {
      '& input': {
        color: '#000000',
        '&::placeholder': {
          color: '#000000',
          textAlign: 'center',
        },
      },
      '& fieldset': {
        borderColor: '#000000',
      },
      '&:hover fieldset': {
        borderColor: '#000000',
        borderWidth: '2px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#000000',
      },
    },
  },
  dropDown: {
    icon: {},
  },
  datePicker: {},
  searchBox: {},
  button: {
    backgroundColor: color.primary,
    borderRadius: 13,
    textTransform: 'none',
  },
  iconButton: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: color.primary,
          color: color.white,
          ':hover': { backgroundColor: color.hover, color: color.primary },
        },
      },
    },
  },
  link: {
    default: {},
    passwordReset: {},
  },
  dataTable: {
    tableContainer: {},
    table: {},
    tableHead: {},
    tableRow: {},
    tableCell: {},
    tableBody: {},
  },
  icon: {},
  breadCrumb: {},
  dropZone: {},
  dynamicList: {},
  counter: {
    default: {},
    withIcon: {},
  },
  pieChart: {},
  circleChart: {},
  lineChart: {},
}

export const ThemeContext = React.createContext(defaultTheme)
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme)
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
