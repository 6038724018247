const routeHandler = async (navigate, user) => {
  const paramRegEx = /.*\?((.*=.*)(&?))+/
  const routes = [
    'login',
    'dashboard',
    'register',
    'reset',
    'reset-password',
    'sink',
  ]
  const correctRoute = routes.includes(window.location.href.split('/')[3])
  if (
    paramRegEx.test(window.location.href) &&
    window.location.href.includes('reset-password')
  )
    return
  if (!correctRoute) {
    if (user !== null) {
      navigate('/dashboard')
    }
    navigate('/login')
  }
  if (/(\/dashboard)+$/g.exec(window.location.href)) {
    if (user === null) navigate('/login')
    if (user.organisations.length < 1) {
      navigate('/login')
    }
  }
}

export default routeHandler
