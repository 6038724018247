const color = {
  primary: '#0D98E8',
  primary2: '#00E7FF',
  primary3: '#040416',
  secondary: '#FF1896',
  secondary2: '#FF2E0E',
  secondary3: '#FF753F',
  secondary4: '#0BE8C1',
  secondary5: '#707070',
  hover: '#707070',
  success: '',
  error: '#ff0000',
  warning: '',
  background: '',
  white: '#FFFFFF',
  black: '#000000',
  placeholder: '#000000',
  shadow: '',
  backgroundColor: '#CBFAFF',
  sidebarHeader: '#000000',
  sidebarBackground: '#FFFFFF',
  // Wireframes
  wireframe: '#FFFFFF',

  // Hover
  primaryHover: '#0c6a9f',
  primary2Hover: '#00B4CBFF',
  secondaryHover: '#c91076',
  errorHover: '#be0000',
  warningHover: '#be0000',
}

export default color
