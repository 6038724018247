import { login } from '@northfoxgroup/frontend-api-lib'
import localStorageService from '../../../utils/localStorageService'

const loginHandler = async (
  values,
  navigate,
  setAlert,
  setLoading,
  setUser,
  setOrganisations
) => {
  setLoading(true)
  const req = await login(values.username, values.password)

  if (req.name === 'AxiosError') {
    setAlert({
      open: true,
      severity: 'error',
      message: 'Error logging into portal user.',
      action: false,
    })
    return setLoading(false)
  }

  if (!['KPI_ROLE', 'ADMIN_ROLE', 'HSE_ROLE'].includes(req.data.user.type)) {
    setAlert({
      open: true,
      severity: 'error',
      message: 'You do not have permission to access the portal.',
      action: false,
    })
    return setLoading(false)
  }

  navigate('/dashboard')
  setLoading(false)
  setUser(req.data.user)
  setOrganisations(req?.data?.user?.organisations)
  localStorageService.set('user', req.data.user)
}

export { loginHandler }
