import React, { useEffect } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import Typography from '../../ui-library/components/Typography'
import Button from '../../ui-library/components/Button'

import LocalStorageService from '../../utils/localStorageService'

// Make sure to bind modal to your appElement
const TimeoutWarningModal = ({ isOpen, onRequestClose }) => {
  const onLogOffCall = () => {
    LocalStorageService.clear()
    // Implement your logout functionality here, e.g. clear the users login cache or hit your signout server
    window.location.href = '/login'
  }

  useEffect(() => {
    setTimeout(() => {
      onLogOffCall()
    }, 60000)
  }, [])

  return (
    <div>
      <Dialog open={isOpen}>
        <DialogTitle>Session Timeout</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography style={{ color: 'black' }}>
              You are being timed out due to inactivity. Please choose to stay
              stay signed in or to logoff in your account. Otherwise, You will
              be automatically logged out in 60 seconds.
            </Typography>
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button source={'error'} onClick={onLogOffCall}>
            Log off
          </Button>
          <Button onClick={onRequestClose}>Stay Logged In</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default TimeoutWarningModal
