import React, { useState } from 'react'
import EditAppUsers from './EditAppUsers'
import AppUsersList from './AppUsersList'
import TabHeader from '../../shared/TabHeader'
import { getFormData } from '../../../resources/formData'

function AppUsers(props) {
  const { tab } = props
  const [edit, setEdit] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedUser, setSelectedUser] = useState()
  const [search, setSearch] = useState('')

  const username = `${selectedUser?.firstname} ${selectedUser?.lastname}`
  return edit ? (
    <>
      <TabHeader
        tabTitle={isEdit ? 'Add User' : `${getFormData(tab, 'screen2Title')}`}
        getColor={getFormData(tab, 'color')}
        userName={username}
        isEdit={isEdit}
      >
        <EditAppUsers
          setEdit={(value) => {
            setEdit(value)
          }}
          tab={tab}
          isEdit={isEdit}
          selectedUser={selectedUser}
        />
      </TabHeader>
    </>
  ) : (
    <>
      <TabHeader
        tabTitle={getFormData(tab, 'title')}
        getColor={getFormData(tab, 'color')}
        showSearch
        onChangeSearch={(e) => setSearch(e.target.value)}
        searchValue={search}
      >
        <AppUsersList
          setEdit={(value) => {
            setEdit(value)
          }}
          setSelectedUser={(value) => setSelectedUser(value)}
          tab={tab}
          setIsEdit={(value) => setIsEdit(value)}
          isEdit={isEdit}
          search={search}
        />
      </TabHeader>
    </>
  )
}

export default AppUsers
