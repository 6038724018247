import React, { useContext } from 'react'
import MUIButton from '@mui/material/Button'
import styled from '@emotion/styled'
import { ThemeContext } from './ThemeContext'
import Typography from './Typography'

const CssButton = styled(MUIButton)(({ theme, source, style }) => {
  return { ...theme?.button, ...theme[source], ...style }
})

const Button = (props) => {
  const { children } = props
  const { theme } = useContext(ThemeContext)
  return (
    <CssButton {...props} theme={theme}>
      <Typography>{children}</Typography>
    </CssButton>
  )
}

CssButton.defaultProps = {
  variant: 'contained',
}

export default Button
