import React, { useContext } from 'react'
import { ThemeContext } from './ThemeContext'
import { AiFillCaretDown } from 'react-icons/ai'
import Select from 'react-select'
import Typography from './Typography'
import color from '../styles/color'

function Dropdown(props) {
  const { theme } = useContext(ThemeContext)
  const {
    id,
    name,
    selected,
    setSelected,
    items,
    inputLabel,
    Icon,
    title,
    source,
    style,
    ...rest
  } = props

  const DropdownIndicator = (props) => {
    return <AiFillCaretDown style={{ color: color.primary }} />
  }

  return (
    <div
      style={{
        ...theme?.dropDown?.container,
        ...theme?.dropDown?.[source]?.container,
        ...style?.container,
      }}
    >
      {title ? (
        <Typography
          {...props}
          source={'inputLabel'}
          style={{ marginBottom: 10 }}
        >
          {title}
        </Typography>
      ) : (
        <></>
      )}
      <Select
        {...rest}
        // defaultValue={}
        styles={{
          ...theme?.dropDown?.select,
          ...theme?.dropDown?.[source]?.select,
          ...style?.select,
        }}
        unstyled
        name="colors"
        options={items}
        className="basic-multi-select"
        classNamePrefix="select"
        value={selected}
        onChange={setSelected}
        components={{ DropdownIndicator }}
        noOptionsMessage={() => ''}
      />
    </div>
  )
}

export default Dropdown

// for custom styling of react-select see https://react-select.com/styles
