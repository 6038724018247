class LocalStorageService {
  static get(key) {
    try {
      let value = localStorage.getItem(key)

      // somewhere undefined is being stored as 'undefined'
      // if that happens, make it actually undefined
      if (value === 'undefined') {
        value = undefined
      }

      return JSON.parse(value)
    } catch (err) {
      return null
    }
  }

  static set(key, value) {
    try {
      if (typeof value === 'object') {
        const valueString = JSON.stringify(value)
        return localStorage.setItem(key, valueString)
      }
      return localStorage.setItem(key, value)
    } catch (err) {
      return null
    }
  }

  static clear() {
    return localStorage.clear()
  }
}

export default LocalStorageService
