import React from 'react'
import Card from '@mui/material/Card'
import style from '../../style/style'

const ErrorCard = (props) => {
  const { type, errors, touched } = props

  return (
    <Card
      className="error"
      sx={errors?.[type] && touched?.[type] ? style.error : {}}
    >
      {errors?.[type] && touched?.[type] ? <div>{errors?.[type]}</div> : <></>}
    </Card>
  )
}

export default ErrorCard
