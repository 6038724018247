import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

// Make sure to bind modal to your appElement
const GenericModal = ({ isOpen, onRequestClose, photo }) => {
  return (
    <Dialog open={isOpen} onClose={onRequestClose}>
      <DialogContent style={{ margin: 0, padding: 0 }}>
        <img
          src={photo}
          alt="photo"
          style={{
            width: '60vh',
            height: 'auto',
            margin: 0,
            padding: 0,
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

export default GenericModal
