import React, { useContext } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import { styled } from '@mui/material/styles'
import { ThemeContext } from './ThemeContext'
import Checkbox from '@mui/material/Checkbox'
import Typography from './Typography'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

const CssCheckBox = styled(Checkbox)((props) => {
  const { theme, type, style } = props
  return { ...theme?.checkBox, ...theme?.checkBox?.[type], ...style }
})

const CheckboxList = (props) => {
  const { setChecked, items } = props
  const { theme } = useContext(ThemeContext)
  return (
    <>
      {items?.map((item, index) => {
        return (
          <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControlLabel
              control={
                <CssCheckBox
                  {...props}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                  theme={theme}
                  checked={item.enabled}
                  onChange={() => setChecked(!item.enabled, index)}
                />
              }
              label={
                <Typography source="checkbox">
                  {item?.displayName ? item?.displayName : item}
                </Typography>
              }
            />
          </div>
        )
      })}
    </>
  )
}

export default CheckboxList
