import React, { useContext, useEffect, useState } from 'react'
import Button from '../../../ui-library/components/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '../../../ui-library/components/Typography'
import { useFormik } from 'formik'
import { editClientSchema } from '../../../config/validation'
import produce from 'immer'
import {
  addClient,
  deleteClient,
  editClient,
  getClientExtras,
} from '../../../handlers/requests/clients/clientsHandler'
import ConfirmationModal from '../../dialogs/ConfirmationModal'
import { AlertContext } from '../../../context/AlertContext'
import TextField from '../../../ui-library/components/TextField'
import DateSelect from '../../../ui-library/components/DateSelect'
import DynamicList from '../../../ui-library/components/DynamicList'
import CheckboxList from '../../../ui-library/components/CheckboxList'
import DropZone from '../../../ui-library/components/DropZone'
import Link from '../../../ui-library/components/Link'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import { ThemeContext } from '../../../ui-library/components/ThemeContext'
import Dropdown from '../../../ui-library/components/Dropdown'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import LoadingButton from '../../../ui-library/components/LoadingButton'
import Card from '@mui/material/Card'

function EditClient(props) {
  let { setEdit, isEdit, selectedClient } = props
  const { setAlert } = useContext(AlertContext)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [logoFormData, setLogoFormData] = useState()
  const [loading, setLoading] = useState(false)
  const { theme } = useContext(ThemeContext)
  const [emailErrors, setEmailErrors] = useState([])
  const [initialEdit, setInitialEdit] = useState({
    name: selectedClient?.name !== null ? selectedClient?.name : '' || '',
    projectNumber: selectedClient?.projectNumber || '',
    projectLocation: selectedClient?.projectLocation || '',
    projectStartDate: selectedClient?.projectStartDate || '',
    projectEndDate: selectedClient?.projectEndDate || '',
    projectPhase: selectedClient?.projectPhase || '',
    LTIRMin: selectedClient?.LTIRMin || 0,
    LTIRMax: selectedClient?.LTIRMax || 0.41,
    TRIRMin: selectedClient?.TRIRMin || 0,
    TRIRMax: selectedClient?.TRIRMax || 1.8,
    DARTMin: selectedClient?.DARTMin || 0,
    DARTMax: selectedClient?.DARTMax || 1.5,
    kpiMultiplier: selectedClient?.kpiMultiplier || 1000000,
    logoUrl: selectedClient?.logo?.fileUrl || '',
  })
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: isEdit
      ? initialEdit
      : {
          name: '',
          projectNumber: '',
          projectLocation: '',
          projectStartDate: null,
          projectEndDate: null,
          projectPhase: '',
          LTIRMin: 0,
          LTIRMax: 0.41,
          TRIRMin: 0,
          TRIRMax: 1.8,
          DARTMin: 0,
          DARTMax: 1.5,
          kpiMultiplier: 1000000,
        },
    validationSchema: editClientSchema,
    enableReinitialize: true,

    onSubmit: () => handleClientSubmit(),
  })
  useEffect(() => {
    setInitialEdit({
      name: selectedClient?.name !== null ? selectedClient?.name : '' || '',
      projectNumber: selectedClient?.projectNumber || '',
      projectLocation: selectedClient?.projectLocation || '',
      projectStartDate: selectedClient?.projectStartDate || '',
      projectEndDate: selectedClient?.projectEndDate || '',
      projectPhase: selectedClient?.projectPhase || '',
      LTIRMin: selectedClient?.LTIRMin || 0,
      LTIRMax: selectedClient?.LTIRMax || 0.41,
      TRIRMin: selectedClient?.TRIRMin || 0,
      TRIRMax: selectedClient?.TRIRMax || 1.8,
      DARTMin: selectedClient?.DARTMin || 0,
      DARTMax: selectedClient?.DARTMax || 1.5,
      kpiMultiplier: {
        label: selectedClient?.kpiMultiplier,
        value: selectedClient?.kpiMultiplier,
      } || {
        label: '1,000,000',
        value: 1000000,
      },
      logoUrl: selectedClient?.logo?.fileUrl || '',
    })
  }, [selectedClient])
  const [multiBoxes, setMultiBoxes] = useState({
    contractors: [],
    areas: [],
    users: [],
    newHseUsers: [],
    deletedUsersIds: [],
  })
  const [checkBoxes, setCheckBoxes] = useState()
  const setCheckboxData = (e, index) => {
    setCheckBoxes(
      produce((draft) => {
        draft[index].enabled = e
      })
    )
    return e
  }
  const setRemovedMulti = (type, editedArray) => {
    setMultiBoxes((prev) => {
      // Add to deleted kpi users
      switch (type) {
        case 'users':
          for (const element of prev[type]) {
            if (!editedArray.find((x) => x.id === element.id)) {
              prev.deletedUsersIds.push(element.id)
            }
          }
          break
        case 'newHseUsers':
          for (const element of prev[type]) {
            if (!editedArray.find((x) => x.id === element.id)) {
              prev.deletedUsersIds.push(element.id)
            }
          }
          break
      }
      return {
        ...prev,
        [type]: editedArray,
      }
    })
  }
  const handleMultiBoxData = (type, id, value, error) => {
    if (multiBoxes[type].some((box) => box.id === id)) {
      setMultiBoxes((prev) => ({
        ...prev,
        [type]: multiBoxes[type].map((box) => {
          if (id === box.id) {
            return { id, value, error }
          } else {
            return box
          }
        }),
      }))
    } else {
      setMultiBoxes((prev) => ({
        ...prev,
        [type]: [...prev[type], { id, value, new: true, error }],
      }))
    }
  }
  const handleClientSubmit = async () => {
    setEmailErrors([])
    setLoading(true)
    const errors = []
    if (isEdit) {
      const allData = {
        organisation: {
          id: selectedClient?.id,
          enabledKPIs: checkBoxes,
          name: values.name,
          projectNumber: values.projectNumber,
          projectLocation: values.projectLocation,
          projectStartDate: values.projectStartDate,
          projectEndDate: values.projectEndDate,
          projectPhase: values.projectPhase,
          LTIRMin: parseFloat(values.LTIRMin),
          LTIRMax: parseFloat(values.LTIRMax),
          TRIRMin: parseFloat(values.TRIRMin),
          TRIRMax: parseFloat(values.TRIRMax),
          DARTMin: parseFloat(values.DARTMin),
          DARTMax: parseFloat(values.DARTMax),
          kpiMultiplier: parseInt(values.kpiMultiplier.value),
        },
        contractors: [],
        areas: [],
        newKpiUsers: [],
        newHseUsers: [],
        deletedUsersIds: multiBoxes.deletedUsersIds,
      }
      multiBoxes.areas.forEach((area) => {
        if (area.value) {
          area.name = area.value
        }
        allData.areas.push(area)
      })
      multiBoxes.contractors.forEach((con) => {
        if (con.value) {
          con.name = con.value
        }
        allData.contractors.push(con)
      })
      multiBoxes.users.forEach((user) => {
        if (user?.error?.length > 0) {
          errors.push(user.error)
        }
        if (user.value) allData.newKpiUsers.push(user.value)
      })
      multiBoxes.newHseUsers.forEach((user) => {
        if (user?.error?.length > 0) {
          errors.push(user.error)
        }
        if (user.value) allData.newHseUsers.push(user.value)
      })
      if (errors.length > 0) {
        setEmailErrors(errors)
        setLoading(false)
        return
      }
      await editClient({
        id: selectedClient?.id,
        updateBody: allData,
        setAlert,
        logoFormData,
      })
      setLoading(false)
      setEdit(false)
    } else {
      const allData = {
        ...values,
        newContractors: [],
        newAreas: [],
        users: [],
        newHseUsers: [],
      }
      multiBoxes.contractors.forEach((contractor) => {
        if (contractor.value) allData.newContractors.push(contractor.value)
      })
      multiBoxes.areas.forEach((area) => {
        if (area.value) allData.newAreas.push(area.value)
      })
      multiBoxes.users.forEach((user) => {
        if (user?.error?.length > 0) {
          errors.push(user.error)
        }
        if (user.value) allData.users.push(user.value)
      })
      multiBoxes.newHseUsers.forEach((user) => {
        if (user?.error?.length > 0) {
          errors.push(user.error)
        }
        if (user.value) allData.newHseUsers.push(user.value)
      })

      if (errors.length > 0) {
        setEmailErrors(errors)
        setLoading(false)
        return
      }
      await addClient({
        values: allData,
        setAlert,
        logoFormData,
      })
    }
    setLoading(false)
    setEdit(false)
  }

  useEffect(() => {
    if (!isEdit) {
      selectedClient = {}
    }
    const multiplierClean = () => {
      switch (selectedClient?.kpiMultiplier) {
        case 200000:
          return setFieldValue('kpiMultiplier', {
            value: 200000,
            label: '200,000',
          })
        case 1000000:
          return setFieldValue('kpiMultiplier', {
            value: 1000000,
            label: '1,000,000',
          })
      }
    }
    multiplierClean()
    const getExtras = async () => {
      const { data } = await getClientExtras(selectedClient?.id)
      const setUsers = []
      const setContractors = []
      const setAreas = []
      const setHseUsers = []
      data?.data?.contractors?.map((contractor) =>
        setContractors.push({ ...contractor, value: contractor.name })
      )
      data?.data?.users?.map((user) =>
        setUsers.push({ ...user, value: user.username })
      )
      data?.data?.areas?.map((area) =>
        setAreas.push({ ...area, value: area.name })
      )
      data?.HSEUsers?.map((user) =>
        setHseUsers.push({ ...user, value: user.username })
      )

      setCheckBoxes(data?.data?.enabledKPIs)

      setMultiBoxes((prev) => ({
        ...prev,
        contractors: [...setContractors, ...prev.contractors],
        areas: [...setAreas, ...prev.areas],
        users: [...setUsers, ...prev.users],
        newHseUsers: [...setHseUsers, ...prev.newHseUsers],
      }))
    }
    getExtras()
  }, [])

  return (
    <>
      <Paper style={theme.dashboard.container}>
        <Link
          onClick={() => {
            setEdit(false)
          }}
        >
          {'< Back'}
        </Link>
        <Typography source="h3">Project Details</Typography>
        {isEdit ? (
          <Button
            source={'error'}
            type="submit"
            onClick={() => {
              setConfirmDelete(true)
            }}
            style={{ width: 160, height: 40, marginTop: 15 }}
            endIcon={<DeleteIcon />}
          >
            Delete Client
          </Button>
        ) : (
          <></>
        )}
        <Typography source="subtitle2">General Project Details</Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
          }}
        >
          <TextField
            id="name"
            name="name"
            title="Project Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            errorType={`name`}
            errors={errors}
            touched={touched}
            source={'bordered'}
          />
          <TextField
            id="projectNumber"
            name="projectNumber"
            title="Project Number (Client)"
            value={values.projectNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            errorType={`projectNumber`}
            errors={errors}
            touched={touched}
            source={'bordered'}
          />
          <TextField
            id="projectLocation"
            name="projectLocation"
            title="Project Location"
            value={values.projectLocation}
            onChange={handleChange}
            onBlur={handleBlur}
            errorType={`projectLocation`}
            errors={errors}
            touched={touched}
            source={'bordered'}
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
          <DateSelect
            id=":rd:"
            name="projectStartDate"
            title={'Project Start Date'}
            label={' '}
            date={values.projectStartDate}
            setDate={(value) => setFieldValue('projectStartDate', value)}
            onBlur={handleBlur}
            touched={touched}
          />
          <DateSelect
            id=":rf:"
            name="projectEndDate"
            title={'Project End Date'}
            label={' '}
            date={values.projectEndDate}
            setDate={(value) => setFieldValue('projectEndDate', value)}
            onBlur={handleBlur}
            touched={touched}
          />
          <TextField
            id="projectPhase"
            name="projectPhase"
            title="Project Phase"
            value={values.projectPhase}
            onChange={handleChange}
            onBlur={handleBlur}
            errorType={`projectPhase`}
            errors={errors}
            touched={touched}
            source={'bordered'}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
          }}
        >
          <div style={{ width: '100%' }}>
            <TextField
              id="LTIRMin"
              name="LTIRMin"
              title="LTIR Min"
              value={values.LTIRMin}
              onChange={handleChange}
              onBlur={handleBlur}
              errorType={`LTIRMin`}
              errors={errors}
              touched={touched}
              source={'bordered'}
            />

            <TextField
              id="LTIRMax"
              name="LTIRMax"
              title="LTIR Max"
              value={values.LTIRMax}
              onChange={handleChange}
              onBlur={handleBlur}
              errorType={`LTIRMax`}
              errors={errors}
              touched={touched}
              source={'bordered'}
            />
          </div>
          <div style={{ width: '100%' }}>
            <TextField
              id="TRIRMin"
              name="TRIRMin"
              title="TRIR Min"
              value={values.TRIRMin}
              onChange={handleChange}
              onBlur={handleBlur}
              errorType={`TRIRMin`}
              errors={errors}
              touched={touched}
              source={'bordered'}
            />
            <TextField
              id="TRIRMax"
              name="TRIRMax"
              title="TRIR Max"
              value={values.TRIRMax}
              onChange={handleChange}
              onBlur={handleBlur}
              errorType={`TRIRMax`}
              errors={errors}
              touched={touched}
              source={'bordered'}
            />
          </div>
          <div style={{ width: '100%' }}>
            <TextField
              id="DARTMin"
              name="DARTMin"
              title="DART Min"
              value={values.DARTMin}
              onChange={handleChange}
              onBlur={handleBlur}
              errorType={`DARTMin`}
              errors={errors}
              touched={touched}
              source={'bordered'}
            />
            <TextField
              id="DARTMax"
              name="DARTMax"
              title="DARTMax"
              value={values.DARTMax}
              onChange={handleChange}
              onBlur={handleBlur}
              errorType={`DARTMax`}
              errors={errors}
              touched={touched}
              source={'bordered'}
            />
          </div>
        </div>
        <Dropdown
          type="kpiMultiplier"
          id={'kpiMultiplier'}
          name={'kpiMultiplier'}
          title={'KPI Multiplier'}
          setSelected={(value) => {
            setFieldValue('kpiMultiplier', value)
            if (value.value === 200000) {
              setFieldValue('DARTMax', 1.5)
              setFieldValue('TRIRMax', 3.5)
              setFieldValue('LTIRMax', 2.7)
            } else {
              setFieldValue('LTIRMax', 0.41)
              setFieldValue('TRIRMax', 1.8)
            }
          }}
          onBlur={handleBlur}
          selected={values.kpiMultiplier}
          items={[
            { key: 2, label: '200,000', value: 200000 },
            { key: 3, label: '1,000,000', value: 1000000 },
          ]}
          inputLabel="KPI Multiplier"
          Icon={ArrowDropDownIcon}
          errorType={'kpiMultiplier'}
          errors={errors}
          touched={touched}
        />
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <form onSubmit={handleSubmit} style={{ width: '70%' }}>
            <Typography source="subtitle2">Logo</Typography>
            <DropZone
              setFile={(value) => setLogoFormData(value)}
              logo={selectedClient?.logo?.fileUrl}
            />
            <DynamicList
              heading="Contractors"
              title="Company Name"
              add="Add Contractor"
              addText={'Add contractor'}
              type="contractors"
              data={multiBoxes}
              setData={(type, id, value, error) =>
                handleMultiBoxData(type, id, value, error)
              }
              setRemoved={(type, value) => setRemovedMulti(type, value)}
            />
            <DynamicList
              heading="Names of Areas"
              title="Area Name"
              add="Add Area"
              type="areas"
              addText="Add area"
              data={multiBoxes}
              setData={(type, id, value, error) =>
                handleMultiBoxData(type, id, value, error)
              }
              setRemoved={(type, value) => setRemovedMulti(type, value)}
            />
            <DynamicList
              heading="People with Access to KPI Platform"
              title="Email Address"
              add="Add an Email Address"
              type="users"
              addText={'Add email'}
              data={multiBoxes}
              setData={(type, id, value, error) =>
                handleMultiBoxData(type, id, value, error)
              }
              setRemoved={(type, value) => setRemovedMulti(type, value)}
              emailValidate={true}
            />
            <DynamicList
              heading="HSE Managers"
              title="HSE Manager"
              add="Add an HSE Manager"
              type="newHseUsers"
              addText={'Add email'}
              data={multiBoxes}
              setData={(type, id, value, error) =>
                handleMultiBoxData(type, id, value, error)
              }
              setRemoved={(type, value) => setRemovedMulti(type, value)}
              emailValidate={true}
            />
            <Typography source="subtitle2">KPIs Enabled</Typography>
            {!isEdit ? (
              <Typography style={{ marginBottom: 50 }}>
                Once you have created a new client, you will be able to choose
                which KPIs they can view. They can be enabled/disabled on the
                edit screen for that client.
              </Typography>
            ) : (
              <>
                <CheckboxList
                  id="checkBoxes"
                  items={checkBoxes}
                  setChecked={(e, index) => {
                    setCheckboxData(e, index)
                  }}
                  onBlur={handleBlur}
                />
              </>
            )}
            {emailErrors.length > 0 && (
              <Card
                className="error"
                sx={{
                  background: '#ff4438',
                  color: 'white',
                  marginBottom: '15px',
                  marginTop: '15px',
                  padding: 1,
                  borderRadius: '10',
                  '&::before': {
                    position: 'absolute',
                    marginTop: '-15px',
                    marginLeft: '6  px',
                    width: 0,
                    height: 0,
                    borderBottom: '9px solid #ff4438',
                    borderLeft: '15px solid transparent',
                    borderRight: '15px  solid transparent',
                  },
                }}
              >
                Please amend errors in email format before saving
              </Card>
            )}
            <LoadingButton
              type="submit"
              source={'secondary'}
              style={{
                width: 140,
                height: 40,
                justifyContent: 'space-between',
                marginTop: 15,
              }}
              loading={loading}
              endIcon={<SaveIcon />}
            >
              Save
            </LoadingButton>
          </form>
        </Stack>

        <ConfirmationModal
          onAccept={async () => {
            await deleteClient(selectedClient?.id, setAlert)
            setEdit(false)
          }}
          isOpen={confirmDelete}
          onRequestClose={() => setConfirmDelete(false)}
          type={'Delete'}
          text={'Are you sure you wish to delete this client?'}
        />
      </Paper>
    </>
  )
}

export default EditClient
