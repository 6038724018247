import React, { useContext, useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Button from '../../../ui-library/components/Button'
import { getAppUsers } from '../../../handlers/requests/users/appUsersHandler'
import { OrganisationContext } from '../../../context/OrganisationContext'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../ui-library/components/DataTable'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { ThemeContext } from '../../../ui-library/components/ThemeContext'
import useDebounce from '../../../hooks/useDebounce'
import { UserContext } from '../../../context/UserContext'
import Typography from '../../../ui-library/components/Typography'
import color from '../../../ui-library/styles/color'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import moment from 'moment'
import uuid from 'react-uuid'
import IconButton from '../../../ui-library/components/IconButton'
import { FaEdit } from 'react-icons/fa'
import LoadingAnim from '../../../ui-library/components/loadingAnim/LoadingAnim'
import '../.././../App.css'

function AppUsersList(props) {
  const { setEdit, setIsEdit, setSelectedUser, isEdit, search } = props
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(false)
  const { currentOrg } = useContext(OrganisationContext)
  const { theme } = useContext(ThemeContext)
  const debouncedSearch = useDebounce(search, 1000)
  const { user } = useContext(UserContext)
  const [sortOrder, setSortOrder] = useState('firstname,ASC')

  useEffect(() => {
    const appUsers = async () => {
      setLoading(true)
      if (debouncedSearch) {
        const users = await getAppUsers(search, user?.type, sortOrder)
        setUserData(users?.data)
        setLoading(false)
      } else {
        const users = await getAppUsers(search, user?.type, sortOrder)
        setUserData(users?.data)
        setLoading(false)
      }
    }
    appUsers()
  }, [isEdit, currentOrg, debouncedSearch, sortOrder])

  const handleEdit = (user) => {
    setEdit(true)
    setIsEdit(false)
    setSelectedUser(user)
  }

  return (
    <Paper
      style={{
        ...theme.dashboard.container,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
      }}
    >
      <Button
        type="submit"
        source={'secondary'}
        style={{ width: 150, height: 40, justifyContent: 'space-between' }}
        endIcon={<AddCircleIcon style={{ width: 25, height: 25 }} />}
        onClick={() => {
          setEdit(true)
          setIsEdit(true)
        }}
      >
        Add User
      </Button>

      <>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <>
            <TableContainer {...props} theme={theme}>
              <Table {...props} theme={theme}>
                <TableHead {...props} theme={theme}>
                  <TableCell {...props} theme={theme}>
                    <Typography
                      style={{
                        color: color.primary,
                        display: 'inline-block',
                        'vertical-align': 'middle',
                        'user-select': 'none',
                      }}
                    >
                      Email
                    </Typography>
                    <div
                      style={{
                        display: 'inline-block',
                        'vertical-align': 'middle',
                        marginTop: 7,
                      }}
                      onClick={() => {
                        if (sortOrder === 'email,DESC')
                          setSortOrder('email,ASC')
                        else {
                          setSortOrder('email,DESC')
                        }
                      }}
                    >
                      {sortOrder === 'email,ASC' ||
                      sortOrder === 'firstname,ASC' ||
                      sortOrder === 'firstname,DESC' ||
                      sortOrder === 'lastLoggedIn,ASC' ||
                      sortOrder === 'lastLoggedIn,DESC' ? (
                        <BiChevronDown
                          style={{
                            width: 25,
                            height: 25,
                            color: color.primary,
                          }}
                        />
                      ) : (
                        <BiChevronUp
                          style={{
                            width: 25,
                            height: 25,
                            color: color.primary,
                          }}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell {...props} theme={theme}>
                    <Typography
                      style={{
                        color: color.primary,
                        display: 'inline-block',
                        'vertical-align': 'middle',
                        'user-select': 'none',
                      }}
                    >
                      Name
                    </Typography>
                    <div
                      style={{
                        display: 'inline-block',
                        'vertical-align': 'middle',
                        marginTop: 7,
                      }}
                      onClick={() => {
                        if (sortOrder === 'firstname,DESC')
                          setSortOrder('firstname,ASC')
                        else {
                          setSortOrder('firstname,DESC')
                        }
                      }}
                    >
                      {sortOrder === 'firstname,ASC' ||
                      sortOrder === 'email,DESC' ||
                      sortOrder === 'email,DESC' ||
                      sortOrder === 'lastLoggedIn,ASC' ||
                      sortOrder === 'lastLoggedIn,DESC' ? (
                        <BiChevronDown
                          style={{
                            width: 25,
                            height: 25,
                            color: color.primary,
                          }}
                        />
                      ) : (
                        <BiChevronUp
                          style={{
                            width: 25,
                            height: 25,
                            color: color.primary,
                          }}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell {...props} theme={theme}>
                    <Typography
                      style={{
                        color: color.primary,
                        display: 'inline-block',
                        'vertical-align': 'middle',
                        'user-select': 'none',
                      }}
                    >
                      Last Login
                    </Typography>
                    <div
                      style={{
                        display: 'inline-block',
                        'vertical-align': 'middle',
                        marginTop: 7,
                      }}
                      onClick={() => {
                        if (sortOrder === 'lastLoggedIn,ASC')
                          setSortOrder('lastLoggedIn,DESC')
                        else setSortOrder('lastLoggedIn,ASC')
                      }}
                    >
                      {sortOrder === 'lastLoggedIn,DESC' ||
                      sortOrder === 'email,DESC' ||
                      sortOrder === 'email,DESC' ||
                      sortOrder === 'firstname,DESC' ||
                      sortOrder === 'firstname,ASC' ? (
                        <BiChevronDown
                          style={{
                            width: 25,
                            height: 25,
                            color: color.primary,
                          }}
                        />
                      ) : (
                        <BiChevronUp
                          style={{
                            width: 25,
                            height: 25,
                            color: color.primary,
                          }}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell {...props} theme={theme} align={'right'}>
                    <Typography
                      style={{
                        color: color.primary,
                        display: 'inline-block',
                        'vertical-align': 'middle',
                        'user-select': 'none',
                        float: 'right',
                      }}
                    >
                      Action
                    </Typography>
                  </TableCell>
                </TableHead>

                <TableBody {...props} theme={theme}>
                  {userData?.map((user) => {
                    const firstname =
                      user?.firstname !== null ? user?.firstname : ''
                    const lastname =
                      user?.lastname !== null ? user?.lastname : ''
                    const email = user?.email !== null ? user?.email : ''
                    const lastLoggedIn = moment(user?.lastLoggedIn).fromNow(
                      true
                    )
                    const notInvalidLastLogin =
                      lastLoggedIn === 'Invalid date' ? 'N/A' : lastLoggedIn
                    return (
                      <TableRow
                        theme={theme}
                        {...props}
                        key={uuid()}
                        className={'tableRow'}
                      >
                        <TableCell theme={theme} {...props} align="left">
                          <Typography>{`${email}`}</Typography>
                        </TableCell>
                        <TableCell theme={theme} {...props}>
                          <Typography>{`${firstname} ${lastname}`}</Typography>
                        </TableCell>
                        <TableCell theme={theme} {...props}>
                          <Typography>{notInvalidLastLogin}</Typography>
                        </TableCell>
                        <TableCell theme={theme} {...props} align={'right'}>
                          <IconButton
                            style={{
                              backgroundColor: 'white',
                              '&:hover': { opacity: 0.5 },
                              float: 'right',
                            }}
                          >
                            <FaEdit
                              style={{
                                color: color.primary,
                              }}
                              onClick={() => {
                                handleEdit(user)
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '50vh',
                }}
              >
                <LoadingAnim />
              </div>
            ) : (
              <></>
            )}
          </>
        </Stack>
      </>
    </Paper>
  )
}

export default AppUsersList
