import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import InputAdornment from '@mui/material/InputAdornment'
import style from '../../style/style'
import { resetSchema } from '../../config/validation'
import logo from '../../assets/light-logo.png'
import { resetHandler } from '../../handlers/requests/auth/resetHandler'
import { AlertContext } from '../../context/AlertContext'
import TextField from '../../ui-library/components/TextField'
import Logo from '../../ui-library/components/Logo'
import Link from '../../ui-library/components/Link'
import uiText from '../../resources/uiText'
import Typography from '../../ui-library/components/Typography'
import color from '../../ui-library/styles/color'
import LoadingButton from '../../ui-library/components/LoadingButton'
import { IoIosArrowDropright } from 'react-icons/io'

export default function Reset() {
  const navigate = useNavigate()
  const { setAlert } = useContext(AlertContext)
  const [signupData] = useState(uiText.reset)

  return (
    <Grid item xs={12}>
      <Formik
        validationSchema={resetSchema}
        initialValues={{
          email: '',
        }}
        onSubmit={async (values) => {
          await resetHandler(values.email, navigate, setAlert)
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div style={style.auth.container}>
              <div style={style.auth.layout}>
                <Logo image={logo} alt="TAS Logo" />
                <Typography source={'subtitle'}>
                  I&apos;ve forgotten my password
                </Typography>
                <TextField
                  name="email"
                  placeholder={signupData.email_label}
                  value={values.email}
                  onChange={handleChange}
                  source={'default'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="TEXT NEEDED">
                          <IconButton>
                            <HelpIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginBottom: 5, marginTop: 5 }}
                />
                <Card
                  className="error"
                  sx={
                    errors.email && touched.email && errors.email
                      ? style.error
                      : {}
                  }
                >
                  {errors?.email && touched?.email && errors?.email}
                </Card>

                <LoadingButton
                  Icon={IoIosArrowDropright}
                  iconStyle={{ color: color.primary }}
                  source={'primary'}
                  type={'submit'}
                  style={{ marginBottom: 5 }}
                >
                  Submit
                </LoadingButton>

                <Link source={'link'} onClick={() => navigate('/login')}>
                  {signupData.link_label}
                </Link>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Grid>
  )
}
