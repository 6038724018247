import React from 'react'
import MuiTooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import HelpIcon from '@mui/icons-material/Help'
import color from '../styles/color'

const ToolTip = ({ text }) => {
  return (
    <MuiTooltip style={{ color: color.primary }} title={text}>
      <IconButton>
        <HelpIcon />
      </IconButton>
    </MuiTooltip>
  )
}

export default ToolTip
