import MuiTableContainer from '@mui/material/TableContainer'
import MuiTable from '@mui/material/Table'
import MuiTableHead from '@mui/material/TableHead'
import MuiTableRow from '@mui/material/TableRow'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableBody from '@mui/material/TableBody'
import styled from '@emotion/styled'

const Table = styled(MuiTable)((props) => {
  const { theme, source, style } = props
  return {
    ...theme?.dataTable?.table,
    ...theme?.dataTable?.[source]?.table,
    ...style,
  }
})
const TableContainer = styled(MuiTableContainer)((props) => {
  const { theme, source, style } = props
  return {
    ...theme?.dataTable?.tableContainer,
    ...theme?.dataTable?.[source]?.tableContainer,
    ...style,
  }
})
const TableHead = styled(MuiTableHead)((props) => {
  const { theme, source, style } = props
  return {
    ...theme?.dataTable?.tableHead,
    ...theme?.dataTable?.[source]?.tableHead,
    ...style,
  }
})
const TableRow = styled(MuiTableRow)((props) => {
  const { theme, source, style } = props
  return {
    ...theme?.dataTable?.tableRow,
    ...theme?.dataTable?.[source]?.tableRow,
    ...style,
  }
})
const TableCell = styled(MuiTableCell)((props) => {
  const { theme, source, style } = props
  return {
    ...theme?.dataTable?.tableCell,
    ...theme?.dataTable?.[source]?.tableCell,
    ...style,
  }
})
const TableBody = styled(MuiTableBody)((props) => {
  const { theme, source, style } = props
  return {
    ...theme?.dataTable?.tableBody,
    ...theme?.dataTable?.[source]?.tableBody,
    ...style,
  }
})

export { Table, TableContainer, TableHead, TableRow, TableCell, TableBody }
