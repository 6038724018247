import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import { AppBar } from '@mui/material'
import Typography from '../../ui-library/components/Typography'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import IconButton from '@mui/material/IconButton'
import Drawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close'
import logo from '../../assets/dark-logo.png'
import { formData } from '../../resources/formData'

import { defaultValues } from '../../config/setting'

import style from '../../style/style'

import AppContext from '../../context/StackContext'

import { changeOrganisation, logout } from '@northfoxgroup/frontend-api-lib'
import { UserContext } from '../../context/UserContext'
import color from '../../ui-library/styles/color'
import Dropdown from '../../ui-library/components/Dropdown'
import localStorageService from '../../utils/localStorageService'
import { OrganisationContext } from '../../context/OrganisationContext'
import Link from '../../ui-library/components/Link'
import Logo from '../../ui-library/components/Logo'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Button from '../../ui-library/components/Button'
import { ThemeContext } from '../../ui-library/components/ThemeContext'

export default function Sidebar(props) {
  const { setTab, getTitle, tab, passOrg } = props
  const navigate = useNavigate()
  const appContext = useContext(AppContext)
  const { user, setUser } = useContext(UserContext)
  const { organisations, setOrganisations, currentOrg, setCurrentOrg } =
    useContext(OrganisationContext)
  const { theme } = useContext(ThemeContext)
  const [open, setState] = useState(false)
  const [isHovering, setIsHovering] = useState()

  useEffect(() => {
    setOrganisations(user?.organisations)
    setCurrentOrg(
      localStorageService.get('currentOrg') || user?.organisations[0]
    )
    changeOrganisation(
      localStorageService.get('currentOrg')?.id || user?.organisations[0].id
    )
    formData.tabs = [
      {
        key: 1,
        title: 'KPIs',
      },
      {
        key: 2,
        title: 'Users',
        screen2Title: 'Edit User',
      },
    ]
    if (user?.type === 'ADMIN_ROLE') {
      formData.tabs[2] = {
        key: 3,
        title: 'Clients',
        screen2Title: 'Edit Client',
      }
      formData.tabs[3] = {
        key: 4,
        title: 'Weekly Data Report',
        screen2Title: 'Edit HSE',
      }
    } else {
      delete formData.tabs[2]
    }
    if (user?.type === 'KPI_ROLE') {
      formData.tabs[3] = {
        key: 4,
        title: 'Weekly Data Report',
        screen2Title: 'Edit HSE',
      }
    }
    if (user?.type === 'HSE_ROLE') {
      formData.tabs[3] = {
        key: 4,
        title: 'Weekly Data Report',
        screen2Title: 'Edit HSE',
      }
      delete formData.tabs[2]
      delete formData.tabs[1]
      delete formData.tabs[0]
    }
    if (user?.type === 'KPI_ROLE') {
      formData.tabs[4] = {
        key: 5,
        title: 'Client',
        screen2Title: 'Edit Client',
      }
    }
  }, [user, organisations])

  const toggleDrawer = (openDrawer, index) => (event) => {
    if (
      event.type === 'keydown' &&
      (event?.key === 'Tab' || event?.key === 'Shift')
    ) {
      return
    }
    // changes the function state according to the value of open
    setState(openDrawer)
    if (index) {
      setTab(index)
    }
  }

  const handleTabChange = (index) => {
    // creating a stack with the history from the user when switching tabs
    const stack = [...appContext.stack]
    stack.push(index)
    appContext.setStack(stack)
    setTab(index)
    toggleDrawer(false)
  }

  const logoutHandler = async () => {
    localStorageService.clear()
    setUser(null)
    navigate('/login')
    await logout()
  }

  const selectOrg = (org) => {
    localStorageService.set('currentOrg', org.value)
    passOrg(org.value)
    setCurrentOrg(org.value)
    changeOrganisation(org.value.id)
  }
  return (
    <Grid>
      <Box
        style={theme?.sidebar?.background}
        sx={{
          display: {
            md: 'block',
            lg: 'block',
            xl: 'block',
            xs: 'none',
            sm: 'none',
          },
        }}
      >
        <div style={theme?.sidebar?.header}>
          <Logo
            image={logo}
            alt="TA Logo"
            onClick={() => setTab(defaultValues.defaultTab)}
            style={{ width: '90%', marginTop: 20 }}
          />
        </div>
        <div style={{ padding: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography
              source={'sideBarHeader'}
              style={{ paddingLeft: 10, paddingRight: 5 }}
            >{`${getTitle(tab)} |  `}</Typography>
            <Typography source={'sideBarHeaderBold'}>
              {(user?.firstname !== null || user?.lastname !== null) &&
                `${user?.firstname}  ${user?.lastname}`}
            </Typography>
          </div>
          {
            <Dropdown
              style={{ container: { width: '93%', margin: 10 } }}
              type="org"
              id={'org'}
              name={'org'}
              setSelected={(value) => {
                selectOrg(value)
              }}
              selected={{ label: currentOrg?.name, value: currentOrg }}
              items={
                organisations?.length > 1
                  ? organisations?.map((org) => ({
                      label: org?.name,
                      value: org,
                    }))
                  : [{ label: '', value: '' }]
              }
              placeholder="Client"
              Icon={ArrowDropDownIcon}
            />
          }
          <List>
            {formData.tabs.map((t) => {
              return (
                <ListItem disablePadding key={t?.key}>
                  <Button
                    source={'menuButton'}
                    sx={tab === t?.key ? color.white : color.primary2}
                    style={{
                      fontWeight: tab === t?.key ? 900 : 100,
                      backgroundColor:
                        isHovering === t?.key
                          ? color.primary2
                          : tab === t?.key
                          ? color.primary2
                          : 'white',
                      borderRadius: 15,
                    }}
                    onClick={() => handleTabChange(t?.key)}
                    onMouseEnter={() => setIsHovering(t?.key)}
                    onMouseLeave={() => setIsHovering(-1)}
                  >
                    <Typography source="tabs">{getTitle(t?.key)}</Typography>
                  </Button>
                </ListItem>
              )
            })}
          </List>
        </div>
      </Box>
      <Box
        textAlign="left"
        style={{ padding: '10px' }}
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Link onClick={logoutHandler}>Logout</Link>
          <Typography source="h7">
            {/*  Remove ?? when variables exist */}
            {process.env.REACT_APP_VERSION ?? 'v1.2.2'}
            {process.env.REACT_APP_SHA ?? 'fa105b2'}
          </Typography>
        </div>
      </Box>
      {/* mobile menu */}
      <AppBar
        style={style.appBar}
        sx={{ display: { md: 'none', lg: 'none', xl: 'none' } }}
      >
        <Logo
          image={logo}
          alt="TA Logo"
          onClick={() => setTab(defaultValues.defaultTab)}
          style={{
            width: '40%',
            marginTop: 20,
            padding: 10,
            borderRadius: 15,
          }}
        />
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer(true)}
          sx={{
            mr: 2,
            display: {
              md: 'none',
              lg: 'none',
              xl: 'none',
            },
          }}
        >
          <MenuIcon aria-label="Open navigation menu" />
        </IconButton>

        {/* The outside of the drawer */}
        <Drawer
          // from which side the drawer slides in
          anchor="right"
          // if open is true --> drawer is shown
          open={open}
        >
          {/* The inside of the drawer */}
          <Box
            sx={{
              padding: '10px 0',
              height: 1,
              backgroundColor: 'white',
              width: '100vw',
            }}
          >
            <IconButton sx={{ mb: 2 }} onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>

            <Box sx={{ mb: 2 }}>
              {formData.tabs.map((t) => {
                return (
                  <ListItem disablePadding key={t?.key}>
                    <Button
                      source={'menuButton'}
                      sx={tab === t?.key ? color.white : color.primary2}
                      style={{
                        fontWeight: tab === t?.key ? 900 : 100,
                        backgroundColor:
                          isHovering === t?.key
                            ? color.primary2
                            : tab === t?.key
                            ? color.primary2
                            : 'white',
                        borderRadius: 15,
                      }}
                      onClick={() => handleTabChange(t?.key)}
                      onMouseEnter={() => setIsHovering(t?.key)}
                      onMouseLeave={() => setIsHovering(-1)}
                    >
                      <Typography variant="tabs">{getTitle(t?.key)}</Typography>
                    </Button>
                  </ListItem>
                )
              })}
            </Box>
            <div style={{ width: '50%', margin: 'auto', textAlign: 'center' }}>
              {user?.organisations?.length > 1 && (
                <Dropdown
                  type="org"
                  id={'org'}
                  name={'org'}
                  setSelected={(value) => {
                    selectOrg(value)
                  }}
                  selected={{ label: currentOrg?.name, value: currentOrg }}
                  items={
                    organisations?.length > 1
                      ? organisations?.map((org) => ({
                          label: org?.name,
                          value: org,
                        }))
                      : [{ label: '', value: '' }]
                  }
                  placeholder="Client"
                  Icon={ArrowDropDownIcon}
                />
              )}
              <Link onClick={logoutHandler}>Logout</Link>
              <Typography source="h7">
                {/*  Remove ?? when variables exist */}
                {process.env.REACT_APP_VERSION ?? 'v1.2.2'}
                {process.env.REACT_APP_SHA ?? 'fa105b2'}
              </Typography>
            </div>
          </Box>
        </Drawer>
      </AppBar>
    </Grid>
  )
}
