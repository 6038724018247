import {
  addOrg,
  deleteSingleOrg,
  getClientData,
  getOrgs,
  updateTransatOrganisation,
  uploadLogo,
} from '@northfoxgroup/frontend-api-lib'

const editClient = async (edit) => {
  const req = await updateTransatOrganisation(edit.updateBody)
  await uploadLogo(edit.logoFormData, edit.id)
  if (req.name === 'AxiosError') {
    return edit.setAlert({
      open: true,
      severity: 'error',
      message: 'Error updating client',
      action: false,
    })
  }
  edit.setAlert({
    open: true,
    severity: 'success',
    message: 'Client updated successfully',
    action: false,
  })
  return req
}

const addClient = async (add) => {
  const body = {
    organisation: {
      name: add.values.name,
      projectNumber: add.values.projectNumber,
      projectLocation: add.values.projectLocation,
      projectStartDate: add.values.projectStartDate,
      projectEndDate: add.values.projectEndDate,
      projectPhase: add.values.projectPhase,
      enabledKPIs: add.values.enabledKPIs,
      LTIRMin: parseFloat(add.values.LTIRMin),
      LTIRMax: parseFloat(add.values.LTIRMax),
      TRIRMin: parseFloat(add.values.TRIRMin),
      TRIRMax: parseFloat(add.values.TRIRMax),
      DARTMin: parseFloat(add.values.DARTMin),
      DARTMax: parseInt(add.values.DARTMax),
    },
    newContractors: add.values.newContractors,
    newAreas: add.values.newAreas,
    kpiUsers: add.values.users,
    HSEUsers: add.values.newHseUsers,
  }
  const req = await addOrg(body)
  await uploadLogo(add.logoFormData, req.data.id)
  if (req.name === 'AxiosError') {
    return add.setAlert({
      open: true,
      severity: 'error',
      message: 'Error adding new client',
      action: false,
    })
  }
  add.setAlert({
    open: true,
    severity: 'success',
    message: 'Client added',
    action: false,
  })
  return req
}
const deleteClient = async (id, setAlert) => {
  const req = await deleteSingleOrg(id)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: 'Error deleting client',
      action: false,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'Client deleted',
    action: false,
  })
  return req
}
const getClients = async (search, sort) => {
  const params = '{"$or": [{"name": {"$contL": "' + search + '"}}]}'

  const orgs = await getOrgs(params, sort)
  return orgs
}

const getClientExtras = async (id) => {
  return await getClientData(id)
}

export { editClient, addClient, getClients, deleteClient, getClientExtras }
