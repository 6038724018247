import React from 'react'
import color from '../styles/color'

const BorderContainer = ({ children }) => {
  return (
    <div
      style={{
        border: '1px',
        borderColor: color.white,
        borderRadius: 15,
        boxShadow: '0px 0px 6px #00000029',
        padding: 10,
        marginBottom: 15,
        marginTop: 0,
      }}
    >
      {children}
    </div>
  )
}

export default BorderContainer
