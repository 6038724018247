import React, { useContext, useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import style from '../style/style'
import Sidebar from '../navigation/sidebar/Sidebar'
import { defaultValues } from '../config/setting'
import { formData, getFormData } from '../resources/formData'
import TimeoutLogicDialog from '../components/dialogs/TimeoutLogicDialog'
import AppUsers from '../components/dashboard/users/AppUsers'
import Clients from '../components/dashboard/clients/Clients'
import KPI from '../components/dashboard/kpi/KPI'
import { UserContext } from '../context/UserContext'
import AltBackground from '../ui-library/components/AltBackground'
import LoadingAnim from '../ui-library/components/loadingAnim/LoadingAnim'
import HSEForm from '../components/dashboard/hse/HSEForm'
import EditClient from '../components/dashboard/clients/EditClient'
import TabHeader from '../components/shared/TabHeader'
import { getClients } from '../handlers/requests/clients/clientsHandler'

export default function Dashboard() {
  const [loading] = useState(false)
  const { user } = useContext(UserContext)
  const [selectedClient, setSelectedClient] = useState(user.organisations[0])
  const [org, setOrg] = useState(user.organisations[0])
  useEffect(() => {
    if (user.type === 'HSE_ROLE') {
      setTab(4)
    }
  }, [])

  const [tab, setTab] = useState(defaultValues.defaultTab)
  const getTitle = (key) => formData.tabs.find((t) => t?.key === key)?.title

  const matchClientToOrg = async () => {
    const res = await getClients(' ', 'name,ASC')
    const client = res?.data?.filter((c) => c?.id === org?.id)
    setSelectedClient(client[0])
  }
  useEffect(() => {
    matchClientToOrg()
  }, [org, tab])
  const getTabContent = () => {
    const { user } = useContext(UserContext)
    switch (tab) {
      case 1:
        return <KPI tab={tab} />
      case 2:
        return <AppUsers tab={tab} />
      case 3:
        return <>{user.type === 'ADMIN_ROLE' ? <Clients tab={tab} /> : <></>}</>
      case 4:
        return <HSEForm tab={tab} />
      case 5:
        return (
          <TabHeader
            tabTitle={getFormData(tab, 'screen2Title')}
            getColor={getFormData(tab, 'color')}
          >
            <EditClient
              tab={tab}
              selectedClient={selectedClient}
              isEdit={true}
            />
          </TabHeader>
        )
      default:
        break
    }
    return <></>
  }

  return (
    <AltBackground backgroundType={'backgroundColor'} value={'#CBFAFF'}>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 300,
          }}
        >
          <LoadingAnim width={300} height={300} />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 50,
          }}
        >
          <Grid container spacing={[3]} sx={style.dashboard}>
            <>
              <Grid item md={3} lg={3} xl={3}>
                <Paper style={{ borderRadius: '10px' }}>
                  <Sidebar
                    tab={tab}
                    getTitle={getTitle}
                    setTab={setTab}
                    passOrg={setOrg}
                  />
                </Paper>
              </Grid>
              <TimeoutLogicDialog />

              <Grid item md={9} lg={9} xl={8} sm={12} xs={12}>
                {getTabContent()}
              </Grid>
            </>
          </Grid>
        </div>
      )}
    </AltBackground>
  )
}
