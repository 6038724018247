import React, { useContext, useState } from 'react'
import TextField from './TextField'
import IconButton from './IconButton'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import zxcvbn from 'zxcvbn'
import { ThemeContext } from './ThemeContext'

const PasswordField = (props) => {
  const { showStrength, source } = props
  const [visible, setVisible] = useState(false)
  const [strength, setStrength] = useState(0)
  const { theme } = useContext(ThemeContext)
  const checkPassword = (value) => {
    const { score } = zxcvbn(value)
    setStrength(score)
  }
  const handleStrength = () => {
    return (
      <div
        style={{
          ...theme?.password?.strengthMeter?.container,
        }}
      >
        <div
          style={{
            backgroundColor: strength >= 1 ? '#ff000b' : '#8a8a8a',
            ...theme?.password?.strengthMeter?.bar,
          }}
        ></div>
        <div
          style={{
            backgroundColor: strength >= 2 ? '#f68339' : '#707070FF',
            ...theme?.password?.strengthMeter?.bar,
          }}
        ></div>
        <div
          style={{
            backgroundColor: strength >= 3 ? '#fffc00' : '#707070FF',
            ...theme?.password?.strengthMeter?.bar,
          }}
        ></div>
        <div
          style={{
            backgroundColor: strength >= 4 ? '#35e503' : '#707070FF',
            ...theme?.password?.strengthMeter?.bar,
          }}
        ></div>
      </div>
    )
  }
  return (
    <>
      <TextField
        {...props}
        value={props.value}
        onChange={(e) => {
          checkPassword(e.target.value)
          props.onChange(e)
        }}
        type={visible ? '' : 'password'}
        icon={
          <IconButton
            style={{ ...theme?.password?.icon }}
            onClick={() => setVisible(!visible)}
          >
            {visible ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
          </IconButton>
        }
        iconPosition={'end'}
        source={source}
      />
      {showStrength ? handleStrength() : ''}
    </>
  )
}

export default PasswordField
