import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { ThemeContext } from './ThemeContext'

const CssNotificationBadge = styled('div')((props) => {
  const { theme, source, style } = props
  return {
    ...theme?.notificationBadge,
    ...theme?.notificationBadge?.[source],
    ...style,
  }
})

const NotificationBadge = (props) => {
  const { number, ...rest } = props
  const { theme } = useContext(ThemeContext)
  return (
    <>
      {number < 1 ? (
        <></>
      ) : (
        <CssNotificationBadge theme={theme} {...rest}>
          {number}
        </CssNotificationBadge>
      )}
    </>
  )
}

export default NotificationBadge
