import React, { useState } from 'react'
export const AlertContext = React.createContext()
export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    open: false,
    severity: 'error',
    message: 'Error logging into portal user.',
    action: false,
  })
  return (
    <AlertContext.Provider value={{ alert, setAlert }}>
      {children}
    </AlertContext.Provider>
  )
}
