import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Button from '../../ui-library/components/Button'
import style from '../../style/style'

function ConfirmationModal({ isOpen, onAccept, onRequestClose, text, type }) {
  return (
    <div>
      <Dialog open={isOpen} style={style.timeoutStyle}>
        <DialogTitle>{type}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography style={{ color: 'black' }}>{text}</Typography>
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button source={'secondary'} onClick={onAccept}>
            Yes
          </Button>
          <Button source={'error'} onClick={onRequestClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmationModal
