import moment from 'moment'

export const defaultValues = {
  defaultTab: 1,
}

moment.updateLocale('en-GB', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds ago',
    ss: '%d seconds ago',
    m: 'a minute ago',
    mm: '%d minutes ago',
    h: '1 hour ago',
    hh: '%d hours ago',
    d: '1 day ago',
    dd: '%d days ago',
    w: '1 week ago',
    ww: '%d weeks ago',
    M: '1 month ago',
    MM: '%d months ago',
    y: '1 year ago',
    yy: '%d years ago',
  },
})

export function timeAgo(date) {
  return moment(new Date(date)).fromNow(true)
}

export function displayDate(date) {
  return moment(new Date(date)).format('DD/MM/YYYY')
}
