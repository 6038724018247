import React, { useContext } from 'react'
import MuiPaper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { ThemeContext } from './ThemeContext'

const Paper = styled(MuiPaper)(({ theme, style, backgroundType, value }) => {
  return {
    [backgroundType]:
      backgroundType === 'backgroundImage' ? `url(${value})` : value,
    backgroundSize: '100% 100%',
    width: '100%',
    height: '100%',
    boxShadow: 'none',
    ...theme?.background,
    ...style,
  }
})

const AltBackground = (props) => {
  const { theme } = useContext(ThemeContext)
  return <Paper {...props} theme={theme} />
}

export default AltBackground
